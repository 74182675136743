import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import CashMovementActions from "./actions/CashMovementActions";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';


class CashMovementResultRow extends React.Component {

  constructor(props) {
    super(props);
    this._onDelete = this._onDelete.bind(this);
  }

  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      CashMovementActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadCashMovementCollection();
        });
    }
  }

  render() {
    return (
      <tr className="cashmovement-item">
        <td>#{ this.props.result.node.field_cash_movement_number[0].value }</td>
        <td>{ this.props.result.node.field_amount[0].value }</td>
        <td>{ this.props.result.node.body[0].value }</td>
        <td>{ this.props.result.user.field_firstname[0].value } { this.props.result.user.field_lastname[0].value }</td>
      </tr>
    );
  }
}

export default CashMovementResultRow;
