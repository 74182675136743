import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';

import GiftCard from '../../../assets/images/qrCard.png' // relative path to image
import BarcodeReader from 'react-barcode-reader'
import { Modal } from 'antd';
import GiftCardActions from "./actions/GiftCardActions";

import moment from 'moment';
import 'moment/locale/de';


class GiftCardForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      result: 'No result',
      modal: false,
      entity: this.props.entity,
      data: {
        title: (this.props.entity && this.props.entity.title[0]) ? this.props.entity.title[0].value : '',
        body: (this.props.entity && this.props.entity.body[0]) ? this.props.entity.body[0].value : '',
        field_amount: (this.props.entity && this.props.entity.field_amount[0]) ? this.props.entity.field_amount[0].value : '',
        field_card_secret_key: (this.props.entity && this.props.entity.field_card_secret_key[0]) ? this.props.entity.field_card_secret_key[0].value : '',
        field_gift_card_number: (this.props.entity && this.props.entity.field_gift_card_number[0]) ? this.props.entity.field_gift_card_number[0].value : '',
      }
    }


    this._onHandleScan = this._onHandleScan.bind(this)

    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onInputChange = this._onInputChange.bind(this);

  }


  _onHandleScan(data){

    const dataParts = data.split("'");

    let component = this;

    let formData = this.state.data;

    formData['field_card_secret_key'] = dataParts[1];
    formData['field_gift_card_number'] = dataParts[0];

    component.setState({
      data: formData
    }, function() {
    });


  }
  _onHandleError(err){
    console.error(err)
  }

  componentDidMount() {
  }

  _onSubmit(e) {
    let component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      data: this.state.data
    }

    if(!this.state.entity) {
      GiftCardActions._post(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadGiftCardCollection();
        }).catch(error => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadGiftCardCollection();
        });
    } else {
      values.nid = this.state.entity.nid[0].value;
      GiftCardActions._patch(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadGiftCardCollection();
        });
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      result: 'No result',
      entity: false,
      data: {
        title: '',
        body: '',
        field_amount: '',
        field_card_secret_key: '',
        field_gift_card_number: '',
      }
    }))
  }

  _onToggleModal = () => {

    this._onFormReset();

    this.setState({
      modal: !this.state.modal
    });
  }

  _onInputChange(e) {

    let name = e.target.name;
    let value = e.target.value;

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  _onSelectChange(e) {
    let name = e.target.name;
    let value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }

    //console.log(e.target.value);

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  render() {
    let component = this;
    return (

      <>




        {!this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Gutschein erfassen</MDBBtn>
        }

        {this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

        <Modal destroyOnClose={true} title="Gutschein scannen" visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

            <form id="giftcard-add-form" onSubmit={this._onSubmit}>

              <div>


                <div className="row">
                  <div className="col-md-12">

                    <div  className={ this.state.data.field_card_secret_key ? 'hide' : '' }>

                      <MDBAlert color="info" >
                        {'Bitte scannen Sie die Gutscheinkarte.'}
                      </MDBAlert>

                      <BarcodeReader
                        onError={this._onHandleError}
                        onScan={this._onHandleScan}
                        preventDefault={true}
                        avgTimeByChar={10}
                        stopPropagation={true}
                        />

                    </div>

                    {this.state.data.field_card_secret_key &&
                      <MDBRow>
                        <MDBCol>

                          <MDBAlert color="success" >
                            {'Gutschein wurde erkannt. Bitte geben Sie den Betrag und die Bestellnummer des Gutscheines an.'}
                          </MDBAlert>

                          <hr />

                            <MDBInput
                              outline
                              name='field_amount'
                              label='Betrag'
                              group
                              type='text'
                              error='wrong'
                              success='right'
                              required
                              onChange={this._onInputChange}
                              value={this.state.data.field_amount}
                            />

                            <MDBInput
                              outline
                              name='body'
                              label='Notiz / Bestellnummer'
                              group
                              type='textarea'
                              error='wrong'
                              success='right'
                              required
                              onChange={this._onInputChange}
                              value={this.state.data.body }
                            />

                          <hr />
                        </MDBCol>
                      </MDBRow>
                    }

                  </div>

                </div>

              </div>


              {this.state.data.field_card_secret_key &&
                <MDBRow>
                  <MDBCol>
                    <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                  </MDBCol>
                </MDBRow>
              }


            </form>
          </Modal>
      </>


    );
  }
}

export default GiftCardForm;
