import React, { Component } from 'react';

/** import config **/
import config from '../../../config';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';

import { Table, Form, Input, Button, Checkbox, InputNumber, Cascader } from 'antd';

import TreatmentDurationAdjustSearch from '../treatmentdurationadjust/TreatmentDurationAdjustSearch';


class EmployeeTreatmentAdjust extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {


    return (
      <>

        <TreatmentDurationAdjustSearch employeeId={this.props.employee.uid[0].value} />

      </>
    );
  }
}

export default EmployeeTreatmentAdjust;
