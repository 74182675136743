import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';


import CashRegisterSessionActions from "./actions/CashRegisterSessionActions";

import moment from 'moment';
import 'moment/locale/de';


class CashRegisterSessionForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      modal: false,
      entity: this.props.entity,
      data: {
        field_cash_register: this.props.nid,
        field_cash_amount: 0
      }
    }


    this._onInputChange = this._onInputChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
  }


  componentDidMount() {
    var component = this;
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      data: this.state.data
    }

    if(!this.state.entity) {
      CashRegisterSessionActions._post(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadCashRegisterSessionCollection();
        });
    } else {
      values.nid = this.state.entity.nid[0].value;
      CashRegisterSessionActions._patch(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadCashRegisterSessionCollection();
        });
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      data: {
        field_cash_register: this.props.nid
      },
      nid: false
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onInputChange(e) {

    var name = e.target.name;
    var value = e.target.value;

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }

    //console.log(e.target.value);

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  _onChangeHandleFile = (file) => {
    var component = this;

    this.setState({
      file: file
    });

    var extension = file.name.split('.').pop().toLowerCase();
    //console.log(extension);

    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         CashRegisterSessionActions._uploadFile(uint8, extension).then(response => {

           var name = 'field_image';

           component.setState(prevState => ({
             data: {                   // object that we want to update
                 ...prevState.data,    // keep all other key-value pairs
                 [name]: response.data.fid[0].value  // update the value of specific key
             }
           }))


         });
    }, false);


    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        fileUrl: URL.createObjectURL(file)
      });
    }
  }

  render() {
    let component = this;
    return (

      <>



        {!this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neue Kassensitzung starten</MDBBtn>
        }

        {this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

      <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Neuen Kasse erfassen</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className='mt-0 text-left'>
            <form id="cashregistersession-add-form" onSubmit={this._onSubmit}>

              <MDBRow>
                <MDBCol>
                  <MDBInput
                    name='field_cash_amount'
                    label='Cash Amount'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    onChange={this._onInputChange}
                    value={this.state.data.field_cash_amount}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  {!this.state.entity &&
                    <MDBBtn color="primary" onClick={this._onFormReset} className="mr-2"><MDBIcon icon="sync mr-2" /> Reset</MDBBtn>
                  }
                  <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                </MDBCol>
              </MDBRow>

            </form>
          </MDBCard>
        </MDBModalBody>
      </MDBModal>
      </>


    );
  }
}

export default CashRegisterSessionForm;
