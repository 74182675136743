import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBFileInput,
  MDBSelect,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBAnimation,
  MDBNavLink,
  MDBCardText,
  MDBLink,
  MDBCollapseHeader,
  MDBCollapse
} from 'mdbreact';
import EmployeeActions from './actions/EmployeeActions';
import { history } from '../../../helpers/history';

import MDBFileupload from 'mdb-react-fileupload';

import Image from "../image/Image";

import PasswordWithGenerator from 'react-password-with-generator';

import Select from 'react-select'

import { Modal } from 'antd';

import NodesToSelect from "../../../helpers/NodesToSelect";
import RolesToSelect from "../../../helpers/RolesToSelect";
import TermsToSelect from "../../../helpers/TermsToSelect";

class EmployeeForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasSubmitted: false,
      modal: false,
      uid: false,
      entity: this.props.entity,
      locationsFilter: this.props.searchComponent.state.locationsFilter,
      rolesFilter: this.props.searchComponent.state.rolesFilter,
      treatmentTypeFilter: this.props.searchComponent.state.treatmentTypeFilter,
      loaded: true,
      username: '',
      password: '',
      mail: '',
      roles: [],
      field_firstname: '',
      field_color: '',
      field_lastname: '',
      field_birthday: '',
      field_contract_start: '',
      field_contract_end: '',
      field_street: '',
      field_zip: '',
      field_city: '',
      field_phone: '',
      field_mobile: '',
      field_image: '',
      field_location: [],
    }

    this.locationFilterRef = React.createRef();
    this.rolesFilterRef = React.createRef();
    this.treatmentTypeFilterRef = React.createRef();
    this.fileInputRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onGetPickerValue = this._onGetPickerValue.bind(this);


    console.log(props);
  }

  componentDidMount() {

    let component = this;

    if(this.props.entity) {


      let locations = this.props.searchComponent.state.locations;
      let selectedLocations = new Array();
      Object.entries(this.props.locations).forEach(function(item, key){
        selectedLocations.push(item[1].nid[0].value.toString());
      })
      let locationsFilter = NodesToSelect.convertToSelect(locations);


      let roles = this.props.searchComponent.state.roles;
      let selectedRoles = new Array();
      Object.entries(this.props.roles).forEach(function(item, key){
        //console.log(item );
        selectedRoles.push(item[1]);
      })
      let rolesFilter = RolesToSelect.convertToSelect(roles);


      let treatmentTypes = this.props.searchComponent.state.treatmentTypes;
      let selectedTreatmentTypes = new Array();
      if(this.props.treatmentTypes) {
        Object.entries(this.props.treatmentTypes).forEach(function(item, key){
          selectedTreatmentTypes.push(item[1].tid[0].value.toString());
        })
      }

      let treatmentTypeFilter = TermsToSelect.convertToSelect(treatmentTypes);
      console.log(treatmentTypes);


      this.setState(prevState => ({
        uid: this.props.entity.uid[0].value,
        username: this.props.entity.name[0].value,
        mail: this.props.entity.mail[0].value,
        field_firstname: this.props.entity.field_firstname[0].value,
        field_lastname: this.props.entity.field_lastname[0].value,
        field_birthday: this.props.entity.field_birthday[0] ? this.props.entity.field_birthday[0].value : null,
        field_contract_start: this.props.entity.field_contract_start[0] ? this.props.entity.field_contract_start[0].value : '',
        field_contract_end: this.props.entity.field_contract_end[0] ? this.props.entity.field_contract_end[0].value : '',
        field_street: this.props.entity.field_street[0].value,
        field_zip: this.props.entity.field_zip[0].value,
        field_city: this.props.entity.field_city[0].value,
        field_phone: this.props.entity.field_phone[0] ? this.props.entity.field_phone[0].value : '',
        field_mobile: this.props.entity.field_mobile[0] ? this.props.entity.field_mobile[0].value : '',
        field_color: this.props.entity.field_color[0] ? this.props.entity.field_color[0].value : '',
        field_location: selectedLocations,
        field_treatment_type: selectedTreatmentTypes,
        locationsFilter: locationsFilter,
        treatmentTypeFilter: treatmentTypeFilter,
        rolesFilter: rolesFilter,
        roles: selectedRoles
      }))
    }


  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSelectChange(e) {

    console.log(e);

    let name = e.target.name;
    let value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }

    if(Array.isArray(value)) {

      let valueArray = new Array();
      Object.entries(value).forEach(function(item, key){
        valueArray.push(item[1].value);
        //console.log(item);
      });

      this.setState({
        [e.target.name]: valueArray,
      });

    } else {
      this.setState({
        [e.target.name]: [e.target.value],
      });
    }


  }

  _onSubmit(e) {
    let component = this;
    e.preventDefault();



    this.setState(prevState => ({
      loaded: false,
      hasSubmitted: true,
    }))

    let values = {
      uid: this.state.uid,
      username: this.state.username,
      password: this.state.password,
      mail: this.state.mail,
      roles: this.state.roles,
      field_firstname: this.state.field_firstname,
      field_lastname: this.state.field_lastname,
      field_birthday: this.state.field_birthday,
      field_contract_start: this.state.field_contract_start,
      field_contract_end: this.state.field_contract_end,
      field_street: this.state.field_street,
      field_zip: this.state.field_zip,
      field_city: this.state.field_city,
      field_phone: this.state.field_phone,
      field_mobile: this.state.field_mobile,
      field_image: this.state.field_image,
      field_location: this.state.field_location,
      field_treatment_type: this.state.field_treatment_type,
      field_color: this.state.field_color,
    }

    if(!this.state.uid) {
      EmployeeActions._post(values)
        .then(response => {
          if(response.data.status == 400) {
            alert(response.data.message);
          } else {
            component.props.searchComponent.loadEmployeeCollection();
            component._onFormReset();
            component._onToggleModal();
          }
        });
    } else {
      EmployeeActions._patch(values)
        .then(response => {
          if(response.data.status == 400) {
            alert(response.data.message);
          } else {
            component.props.searchComponent.loadEmployeeCollection();
            component._onToggleModal();
          }
        });
    }
  }

  _onInputChange(e) {
    console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });

    //console.log(this.state);
  }

  _onChangeHandleFile = (file) => {
    let component = this;
    let reader = new FileReader();
    reader.addEventListener("load", function () {
         let dataString = reader.result;
         let uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         EmployeeActions._uploadImage(uint8).then(response => {
           component.setState({
             field_image: response.data.fid[0].value
           });
         });
    }, false);

    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        imageUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {

    if(this.rolesFilterRef.current) {
      this.rolesFilterRef.current.select.clearValue();
    }

    if(this.locationFilterRef.current) {
      this.locationFilterRef.current.select.clearValue();
    }

    if(this.locationFilterRef.current) {
      this.locationFilterRef.current.select.clearValue();
    }

    if(this.fileInputRef.current) {
      this.fileInputRef.current.resetPreview();
    }



    let roles = this.props.searchComponent.state.roles;
    let rolesFilter = RolesToSelect.convertToSelect(roles);

    let locations = this.props.searchComponent.state.locations;
    let locationsFilter = NodesToSelect.convertToSelect(locations);

    let treatmentTypes = this.props.searchComponent.state.treatmentTypes;
    let treatmentTypeFilter = TermsToSelect.convertToSelect(treatmentTypes);


    this.setState(prevState => ({
      hasSubmitted: false,
      modal: false,
      uid: false,
      loaded: true,
      username: '',
      password: '',
      mail: '',
      roles: [],
      field_firstname: '',
      field_color: '',
      field_lastname: '',
      field_birthday: '',
      field_contract_start: '',
      field_contract_end: '',
      field_street: '',
      field_zip: '',
      field_city: '',
      field_phone: '',
      field_mobile: '',
      field_image: '',
      field_location: [],
      field_treatment_type: [],
    }))
  }

  _onGetPickerValue = (name, value) => {
    console.log(value);
    //let date = new Date(value).toJSON();
    this.setState({
      [name]: value
    });
  }

  render() {
    let component = this;

    let defaultRoleValue = new Array();
    let defaultLocationValue = new Array();
    let defaultTreatmentTypeValue = new Array();

    if(this.state.uid) {


      defaultRoleValue = RolesToSelect.getSelectedValue(component.props.searchComponent.state.rolesFilter, component.props.roles);
      defaultLocationValue = NodesToSelect.getSelectedValue(component.props.searchComponent.state.locationsFilter, component.props.locations);
      defaultTreatmentTypeValue = TermsToSelect.getSelectedValue(component.props.searchComponent.state.treatmentTypeFilter, component.props.treatmentTypes);

    }



    return (
      <>
      {!this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Mitarbeiter erfassen</MDBBtn>
      }

      {this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
      }


      <Modal width={1000} destroyOnClose={true} title="Formular" visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

          <MDBCard className='mt-0'>


            <form onSubmit={this._onSubmit} id="employee-add-form">


              <MDBRow>
                <MDBCol size="12" className="hide">
                    <MDBFileupload
                        maxFileSize="16M"
                        ref={this.fileInputRef}
                        getValue={this._onChangeHandleFile}
                      />
                </MDBCol>
                <MDBCol size="12">
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    outline
                    name='field_firstname'
                    label='Vorname'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_firstname}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    outline
                    name='field_lastname'
                    label='Name'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_lastname}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>

                    <MDBInput outline type="date" id="field_birthday" label="Geburtstag" placeholder="Geburtstag" name="field_birthday" value={this.state.field_birthday} onChange={this._onInputChange} required />

                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <MDBInput
                    outline
                    type="date"
                    id="field_contract_start"
                    label="Vertragsstart"
                    placeholder="Vertragsstart"
                    name="field_contract_start"
                    value={this.state.field_contract_start}
                    onChange={this._onInputChange}
                    required
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    outline
                    type="date"
                    id="field_contract_end"
                    label="Vertragsende"
                    placeholder="Vertragsende"
                    name="field_contract_end"
                    value={this.state.field_contract_end}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    outline
                    name='field_street'
                    label='Strasse'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_street}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    outline
                    name='field_zip'
                    label='PLZ'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_zip}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    outline
                    name='field_city'
                    label='Ort'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_city}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol>
                  <MDBInput
                    outline
                    name='mail'
                    label='E-Mail'
                    group
                    type='email'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.mail}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    outline
                    name='field_mobile'
                    label='Mobil'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_mobile}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    outline
                    name='field_phone'
                    label='Telefon'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    value={this.state.field_phone}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol className="text-left">
                  <div class="md-form form-group md-outline">
                      <Select
                        ref={this.rolesFilterRef}
                        menuContainerStyle={{'zIndex': 999}}
                        required
                        isMulti
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Rolle"
                        defaultValue={defaultRoleValue}
                        options={this.state.rolesFilter}
                        isClearable={true}
                        onChange={(value) => {

                          if(value) {
                            value = value;
                          } else {
                            value = '';
                          }


                          this._onSelectChange({
                            target: {
                              name: 'roles',
                              value: value,
                            },
                          });
                        }}
                      />
                  </div>
                </MDBCol>
                <MDBCol className="text-left">
                  <div class="md-form form-group md-outline">
                      <Select
                        ref={this.locationFilterRef}
                        required
                        isMulti
                        defaultValue={defaultLocationValue}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Standort"
                        options={this.state.locationsFilter}
                        isClearable={true}
                        onChange={(value) => {

                          console.log(value);

                          if(value) {
                            value = value;
                          } else {
                            value = '';
                          }

                          this._onSelectChange({
                            target: {
                              name: 'field_location',
                              value: value,
                            },
                          });
                        }}
                      />
                  </div>
                </MDBCol>
                <MDBCol className="text-left">
                  <div class="md-form form-group md-outline">
                      <Select
                        ref={this.treatmentTypeFilterRef}
                        required
                        isMulti
                        defaultValue={defaultTreatmentTypeValue}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Behandlungen"
                        options={this.state.treatmentTypeFilter}
                        isClearable={true}
                        onChange={(value) => {

                          console.log(value);

                          if(value) {
                            value = value;
                          } else {
                            value = '';
                          }

                          this._onSelectChange({
                            target: {
                              name: 'field_treatment_type',
                              value: value,
                            },
                          });
                        }}
                      />
                  </div>
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol>
                  <MDBInput
                    outline
                    name='field_color'
                    label='Farbe'
                    group
                    type='color'
                    error='wrong'
                    success='right'
                    required
                    className="input-color"
                    value={(this.state.field_color) ? this.state.field_color : '#000'}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <MDBInput
  outline                   name='username'
                    label='Benutzername'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.username}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>



                  {/*
                  {!this.state.entity &&
                    <div className="md-form form-group">
                      <label style={{ fontSize: "1rem", transform: "translateY(-14px) scale(0.8)", position: "absolute", top: "0px", left: "0px", color: "#4285f4" }}>Passwort</label>
                      <PasswordWithGenerator name='password' onBlur={this._onInputChange} onFocus={this._onInputChange} className="" required />
                    </div>
                  }

                  {this.state.entity &&
                    <div className="md-form form-group">
                      <label style={{ fontSize: "1rem", transform: "translateY(-14px) scale(0.8)", position: "absolute", top: "0px", left: "0px", color: "#4285f4" }}>Passwort</label>
                      <PasswordWithGenerator name='password' onBlur={this._onInputChange} onFocus={this._onInputChange} readOnly className="" />
                    </div>
                  }
                  */}



                  {!this.state.entity &&
                    <MDBInput
                      outline
                      name='password'
                      label='Passwort'
                      group
                      type='password'
                      error='wrong'
                      success='right'
                      value={this.state.password}
                      onChange={this._onInputChange}
                    />
                  }

                  {this.state.entity &&
                        <MDBInput
                          outline
                          name='password'
                          label='Passwort'
                          group
                          type='password'
                          error='wrong'
                          success='right'
                          value={this.state.password}
                          onChange={this._onInputChange}
                        />
                  }






                </MDBCol>
              </MDBRow>




                <MDBRow>
                  <MDBCol>
                    <div className="form-action-bar">
                      {!this.state.entity &&
                        <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2 hide"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                      }
                      <MDBBtn color="primary" type="submit" disabled={((this.state.hasSubmitted)) ? true : false}><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>

            </MDBCol>
          </MDBRow>

            </form>

          </MDBCard>
      </Modal>

      </>


    );
  }
}

export default EmployeeForm;
