import React, { Component } from 'react';
import Pager from 'react-pager';
import UserCardResultRow from "./UserCardResultRow";
import UserCardActions from "./actions/UserCardActions";
import Image from "../image/Image";
import UserCardFilter from "./UserCardFilter";
import UserCardForm from "./UserCardForm";
import PermissionHelper from "../../../helpers/PermissionHelper";
import { Empty } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';


class UserCardSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      collectionLoaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_searchterm: null,
        field_user: this.props.uid
      }
    }


    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadUserCardCollection();
    });
  }

  componentDidMount(){
    this.loadUserCardCollection();
    //console.log(this);
  }

  loadUserCardCollection() {


      var component = this;

      if(this.props.uid) {
        /** build filter query **/

        UserCardActions._get('All', this.state.filters, this.state.page).then(response => {

          var result = [];
          if(response.data.result) {
            result = Object.entries(response.data.result);
          }

          component.setState({
            result: result,
            loaded: true,
            collectionLoaded: true,
            total: response.data.total
          });

        });
      } else {
        component.setState({
          loaded: true,
          collectionLoaded: true,
        });
      }


  }

  render() {
    let component = this;
    return (
      <>

      {PermissionHelper._hasPermission('restful post UserCard') === true && component.state.result.length == 0 &&
        <>
          <UserCardForm searchComponent={this} ref={this.formRef} uid={this.props.uid} />
            <hr />
        </>
      }



        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'usercard-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

          {/*<UserCardFilter searchComponent={this} /><hr />*/}

            { component.state.result.length > 0 && (


              <MDBTable responsive hover striped className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Kartennummer
                    </th>
                    <th className='th-lg'>
                      erstellt am
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      console.log(row)
                      return (
                        <UserCardResultRow result={row[1]}  key={'usercard-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default UserCardSearch;
