import React, { Component } from 'react';
import {
  MDBCard,
  MDBSpinner,
  MDBLink,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBAnimation,
  MDBBadge
} from 'mdbreact';
import PermissionHelper from "../../../helpers/PermissionHelper";
import { history } from '../../../helpers/history';

import NumberFormat from 'react-number-format';

import OrderActions from "./actions/OrderActions";

import OrderItemSearch from "../orderitem/OrderItemSearch";

import Totals from '../totals/Totals';

import OrderTransactionSearch from "../ordertransaction/OrderTransactionSearch";

import CreditMemoSearch from "../creditmemo/CreditMemoSearch";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import OrderComment from "../comment/CommentSearch.jsx";

import moment from 'moment';
import 'moment/locale/de';

/** import styles **/
import '../../../assets/css/sections/order.css';



class OrderDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      entity: null,
      nid: this.props.match.params.id,
      activeItem: "1",
    };

    this._onDelete = this._onDelete.bind(this);
    this._onTabToggle = this._onTabToggle.bind(this);
  }

  componentDidMount() {
    OrderActions._get(this.props.match.params.id).then(response => {
      console.log(response);
      this.setState({
        entity: response.data.entity,
        loaded: true
      });
    });
  }

  _onTabToggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };



  _onDelete() {
    const r = window.confirm("Löschen Bestätigen");
    if(r === true){
      this.setState({
        loaded: false
      });
      OrderActions._delete(this.props.match.params.id).then(response => {
        history.push('/customer');
      });
    }
  }

  render() {

    let component = this;

    return (
      <section id="order-detail">
        <MDBCard>
          <MDBCardBody tag="div">
            <MDBCardTitle>
              <MDBRow>
                <MDBCol size="6" className="">
                    Bestellung: #
                    {this.state.entity &&
                      <span>{this.state.entity.node.field_order_number[0].value}</span>
                    }
                </MDBCol>
                <MDBCol size="6">

                  {this.state.entity &&
                  <div className="action-bar">
                      <MDBBtn onClick={() => { history.push('/order') }} size="sm" color="info">
                        <MDBIcon icon="chevron-left" />
                      </MDBBtn>
                  </div>
                  }

                </MDBCol>
              </MDBRow>
            </MDBCardTitle>




            {!this.state.loaded &&
              <div className="text-center">
                <MDBSpinner red />
              </div>
            }


            {this.state.loaded &&
              <MDBCardText tag="div">
                <div className="row">
                  <div className="col-md-6">
                      <div className="item">
                        <div className="label">Bestelldatum</div>
                        <div className="value">
                          {moment(this.state.entity.node.created[0].value).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")}
                        </div>
                      </div>

                      <div className="item">
                        <div className="label">Status</div>
                        <div className="value">
                          {this.state.entity.status.name[0].value}
                        </div>
                      </div>

                      <div className="item">
                        <div className="label">Kunde</div>
                        <div className="value">
                          {this.state.entity.customer &&
                            <>{this.state.entity.customer.field_firstname[0].value} {this.state.entity.customer.field_lastname[0].value} (#{this.state.entity.customer.field_customer_number[0].value})</>
                          }
                          {!this.state.entity.customer &&
                            <>Laufkundschaft</>
                          }

                        </div>
                      </div>


                      <div className="item">
                        <div className="label">Verkäufer</div>
                        <div className="value">
                          {this.state.entity.user &&
                            <>{this.state.entity.user.field_firstname[0].value} {this.state.entity.user.field_lastname[0].value}</>
                          }


                        </div>
                      </div>


                  </div>
                  <div className="col-md-6">
                  </div>
                </div>


                <div className="row">
                  <div className="col-md-12">
                    <MDBNav tabs>
                      <MDBNavItem>
                        <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this._onTabToggle("1")} role="tab">
                          Bestellte Artikel
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this._onTabToggle("2")} role="tab">
                          Gutschriften
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this._onTabToggle("3")} role="tab">
                          Transaktionen
                        </MDBNavLink>
                      </MDBNavItem>

                      <MDBNavItem>
                        <MDBNavLink link to="#" active={this.state.activeItem === "4"} onClick={this._onTabToggle("4")} role="tab">
                          Kommentare
                        </MDBNavLink>
                      </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent className="card-no" activeItem={this.state.activeItem}>
                      <MDBTabPane tabId="1" role="tabpanel">


                        <div className="row">
                          <div className="col-md-8">
                            <OrderItemSearch orderItems={this.state.entity.orderItems} />
                          </div>
                          <div className="col-md-4">
                            <Totals order={this.state.entity} />
                          </div>
                        </div>

                      </MDBTabPane>
                      <MDBTabPane tabId="2" role="tabpanel">
                        <CreditMemoSearch order={this.state.entity} orderId={this.state.entity.node.nid[0].value} orderItems={this.state.entity.orderItems} />
                      </MDBTabPane>
                      <MDBTabPane tabId="3" role="tabpanel">
                        <OrderTransactionSearch orderId={this.state.entity.node.nid[0].value} />
                      </MDBTabPane>
                      <MDBTabPane tabId="4" role="tabpanel">
                        <OrderComment component={this} entityId={this.state.entity.node.nid[0].value} entityType="node" fieldName="field_order_comment" commentType="order_comment" showImportant={true} />
                      </MDBTabPane>
                    </MDBTabContent>
                  </div>
                </div>

              </MDBCardText>
            }




          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

//export default Customers;
export default OrderDetail;
