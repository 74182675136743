import React, { Component } from 'react';
import {
  MDBSideNavLink,
  MDBIcon,
  MDBBadge
} from 'mdbreact';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import PermissionHelper from "../../../helpers/PermissionHelper";


class VacationMenuLink extends Component {

  constructor(props) {
    super(props);

    this._onClick = this._onClick.bind(this);
  }

  componentDidMount() {
  }


  _onClick() {
    this.props.onClick();
  }

  render() {
    if(PermissionHelper._hasPermission('restful post Vacation') === true) {
      return (
        <MDBSideNavLink topLevel to='/vacation' onClick={this._onClick}>
          <FontAwesomeIcon icon={AllLightIcons.faPlaneDeparture} />
          Ferien
        </MDBSideNavLink>
      )
    } else {
      return (
        <></>
      )
    }
  }
}

export default VacationMenuLink;
