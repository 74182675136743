import React, { Component } from 'react';


/** import config **/
import config from '../../../config';
import { MDBContainer, MDBRow, MDBCol, MDBStepper, MDBStep, MDBBtn, MDBInput, MDBSpinner, MDBDatePickerV5 } from "mdbreact";
import { connect } from 'react-redux';
import { withTranslation, initReactI18next } from "react-i18next";

import AdminCardSection1 from './sections/AdminCardSection1';
import AdminCardSection2 from './sections/AdminCardSection2';
import TableSection from './sections/TableSection';
import BreadcrumSection from './sections/BreadcrumSection';
import ChartSection1 from './sections/ChartSection1';
import ChartSection2 from './sections/ChartSection2';
import ModalSection from './sections/ModalSection';

import StatisticActions from "./actions/DashboardActions";
import DashboardFilter from "./DashboardFilter";


import DailyStats from './sections/DailyStats';
import WeeklyStats from './sections/WeeklyStats';
import WeeklySalesStats from './sections/WeeklySalesStats';
import SellStats from './sections/SellStats';


/** import styles **/
import '../../../assets/css/sections/dashboard.css';




class Dashboard extends Component {

  constructor(props) {
    super(props);



    this.state = {
      loaded: false,
      filters: {
        field_location: localStorage.getItem('selectedLocation'),
        field_from: null,
        field_to: null,
        field_ignore_location: false
      },
      page: 0,
      result: []
    }
 }

  componentDidMount() {
    console.log(this);


    var component = this;


    var dashboardFilter = JSON.parse(localStorage.getItem('dashboardFilter'));

    if(dashboardFilter) {
      dashboardFilter.filters.field_from = new Date(dashboardFilter.filters.field_from);
      dashboardFilter.filters.field_to = new Date(dashboardFilter.filters.field_to);
      dashboardFilter.filters.field_location = localStorage.getItem('selectedLocation');
      component.setState({
        filters: dashboardFilter.filters
      }, function() {
        //console.log('1');
        //return;
        component.loadData();
      });
    } else {
      //console.log('2');
      //return;
      let dashboardFilter = component.state.filters;

      dashboardFilter.field_location = localStorage.getItem('selectedLocation');
      var fromDate = new Date(new Date().setHours('00','00','00'));
      var toDate = new Date(new Date().setHours('23','59','50'));
      dashboardFilter.field_from = fromDate.toJSON();
      dashboardFilter.field_to = toDate.toJSON();

      component.setState({
        filters: dashboardFilter
      }, function() {
        //console.log('1');
        //return;
        component.loadData();
      });
    }




  }


  loadData() {


    console.log(this.state.filters);

    this.setState(prevState => ({
      result: [],
      loaded: false
    }))


    var filters = this.state.filters;

    filters.field_from = new Date(filters.field_from).toJSON();
    filters.field_to = new Date(filters.field_to).toJSON();

    StatisticActions._get('dashboard', filters, this.state.page).then(response => {


        this.setState(prevState => ({
          result: response.data.data,
          loaded: true
        }))

      });
  }


  render() {


    return (
      <section id="dashboard">


        <DashboardFilter dashboard={this} />

        <hr />

            <>
            <DailyStats dashboard={this} />

            <WeeklyStats dashboard={this} />

            <WeeklySalesStats dashboard={this} />

            <SellStats dashboard={this} />

            {/*<StackedStats dashboard={this} />*/}
            </>



      </section>
    );
  }
}

export default Dashboard;
