import { Component } from 'react';

class NodesToSelect extends Component {
  constructor(props) {
    super(props);
  }

  static convertToSelect(nodes) {
    let nodesFilter = new Array();
    if(nodes) {
      Object.entries(nodes).forEach(function(item, key){
        let optionItem = {
          label: item[1].title[0].value,
          value: item[0]
        }
        nodesFilter.push(optionItem);
        //console.log(item);
      });

      return nodesFilter;
    }
  }


  static getSelectedValue(nodesFilter, nodes) {
    let defaultNodeValue = new Array();
    Object.entries(nodes).forEach(function(item, key){
      console.log(item);
      if(nodesFilter.find(x => x.value === (item[1].nid[0].value).toString())) {
        let selectedItem = { label: nodesFilter.find(x => x.value === (item[1].nid[0].value).toString()).label, value: (item[1].nid[0].value).toString() };
        defaultNodeValue.push(selectedItem);
      }
    })

    return defaultNodeValue;
  }
}

export default NodesToSelect;
