import React, { Component } from 'react';
import Pager from 'react-pager';
import OrderItem from "./OrderItem";
import Image from "../image/Image";
import PermissionHelper from "../../../helpers/PermissionHelper";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';


class OrderItemSearch extends Component {

  constructor(props) {
    super(props);
  }



  componentDidMount(){
  }


  render() {
    let component = this;


    return (
      <>
      <MDBCard className="w-100">
        <MDBCardBody>
          {component.props.orderItems.length > 0 && (
            <table className="w-100 table">
                <thead>
                  <tr>
                    <th>Menge</th>
                    <th>Name</th>
                    <th>Preis</th>
                  </tr>
                </thead>
                <tbody>
                {true && (
                  Object.entries(component.props.orderItems).map(function(row, j){
                    console.log(row)
                    return (
                      <OrderItem result={row[1]}  key={'order-item-' + row[1].node.nid[0].value + row[1].node.changed[0].value} searchComponent={component} />
                    )
                  })
                )}
                </tbody>
            </table>
          )}

          {component.props.orderItems.length == 0 &&
            <div className="empty">Keine Positionen vorhanden</div>
          }

        </MDBCardBody>
      </MDBCard>
      </>
    );
  }
}

export default OrderItemSearch;
