import React, { Component } from 'react';

/** import config **/
import config from '../../../config';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';



import NewsletterSearch from "./NewsletterSearch";



/** import styles **/
import '../../../assets/css/sections/newsletter.css';


class NewsletterOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <section id="newsletter-overview">
        <MDBCard className="card-newsletter">
          <MDBCardBody>
            <MDBCardTitle>Newsletter</MDBCardTitle>
            <MDBCardText tag="div">
              <NewsletterSearch />
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

export default NewsletterOverview;
