import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';

import PermissionHelper from "../../../helpers/PermissionHelper";


import Select from 'react-select';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';

class VacationFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    }

    this.statusOptions = [
     { value: '1', label: 'Aktiv' },
     { value: '0', label: 'Inaktiv' },
   ]

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onDirectionChange = this._onDirectionChange.bind(this);
  }

  componentDidMount() {
    this.setState(prevState => ({
        loaded: true
    }));
  }

  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onDirectionChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this._updateSearchFilter(filters);
  }

  _onInputChange(e) {
    //console.log(e);
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _updateSearchFilter(filters) {

    var component = this;

    //console.log(filters);
    component.props.searchComponent.setState({
        filters: filters,
        result: [],
        page: 0
    }, () => {
        var vacationFilter = component.props.searchComponent.state;
        //localStorage.setItem('vacationFilter', JSON.stringify(vacationFilter));
        component.props.searchComponent.loadVacationCollection();
    });
  }

  render() {
    let component = this;

    console.log(this.props.searchComponent.state.locationsFilter);



    var defaultLocationFilterValue = {};
    if(component.props.searchComponent.state.filters.field_location) {
      defaultLocationFilterValue = { label: component.props.searchComponent.state.locationsFilter.find(x => x.value ===(Number(component.props.searchComponent.state.filters.field_location))).label, value: (component.props.searchComponent.state.filters.field_location.toString()) }
    }

    return (
      <div className="filter-card">

        <MDBRow>
          <MDBCol md="2" xs="12">
            <Select
              required
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Standort"
              options={component.props.searchComponent.state.locationsFilter}
              defaultValue={defaultLocationFilterValue}
              isClearable={true}
              onChange={(value) => {

                if(value) {
                  value = value.value;
                } else {
                  value = '';
                }

                this._onSelectChange({
                  target: {
                    name: 'field_location',
                    value: value,
                  },
                });
              }}
            />
          </MDBCol>

          {PermissionHelper._hasPermission('show inactive users') === true &&
            <MDBCol md="1" xs="12">
              <Select
                required
                className="react-select"
                classNamePrefix="react-select"
                placeholder="Status"
                  options={this.statusOptions }
                isClearable={true}
                onChange={(value) => {

                  if(value) {
                    value = value.value;
                  } else {
                    value = '';
                  }

                  this._onSelectChange({
                    target: {
                      name: 'field_status',
                      value: value,
                    },
                  });
                }}
              />
            </MDBCol>
          }

          <MDBCol md="3" xs="12">



            <Select
              required
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Rollen"
              options={this.props.searchComponent.state.rolesFilter}
              isClearable={true}
              onChange={(value) => {

                if(value) {
                  value = value.value;
                } else {
                  value = '';
                }

                this._onSelectChange({
                  target: {
                    name: 'field_role',
                    value: value,
                  },
                });
              }}
            />

          </MDBCol>


          <MDBCol md="3" xs="12">

            <Select
              required
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Jahr"
              options={this.props.searchComponent.state.yearOptions}
              isClearable={true}
              onChange={(value) => {

                if(value) {
                  value = value.value;
                } else {
                  value = '';
                }

                this._onSelectChange({
                  target: {
                    name: 'field_year',
                    value: value,
                  },
                });
              }}
            />

          </MDBCol>

          <MDBCol md="3" xs="12">

            <Select
              required
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Kategorie"
              options={this.props.searchComponent.state.vacationCategoryFilter}
              isClearable={true}
              onChange={(value) => {

                if(value) {
                  value = value.value;
                } else {
                  value = '';
                }

                this._onSelectChange({
                  target: {
                    name: 'field_vacation_category',
                    value: value,
                  },
                });
              }}
            />


          </MDBCol>


          {/*
            <MDBCol md="3" xs="12" className="d-flex col-sort">
              <MDBSelect
                  options={this.props.searchComponent.state.sortOptions}
                  selected="Sortierung"
                  label="Sortierung"
                  id="field_sort"
                  name="field_sort"
                  getValue={(e) => {
                    this._onSelectChange({
                      target: {
                        name: 'field_sort',
                        value: e,
                      },
                    });
                  }}
              />

            <MDBBtn flat size="lg" color="primary" onClick={(e) => {
                this._onDirectionChange({
                  target: {
                    name: 'field_sort_direction',
                    value: (this.props.searchComponent.state.filters.field_sort_direction == 'asc') ? "desc" : "asc",
                  },
                });
            }}>
            {this.props.searchComponent.state.filters.field_sort_direction == 'asc' &&
              <MDBIcon icon="sort-alpha-down-alt" />
            }

            {this.props.searchComponent.state.filters.field_sort_direction == 'desc' &&
              <MDBIcon icon="sort-alpha-up-alt" />
            }

            </MDBBtn>

            </MDBCol>*/
          }
        </MDBRow>
        <hr />
          <MDBRow>
            <MDBCol>
                <div className="sui-search-box">
                  <div className="sui-search-box__wrapper">
                    <DebounceInput
                      minLength={2}
                      debounceTimeout={300}
                      id="field_searchterm"
                      name="field_searchterm"
                      placeholder="Mitarbeiternummer, E-Mail, Name"
                      className="sui-search-box__text-input "
                      onChange={(e) => {
                        this._onInputChange({
                          target: {
                            name: 'field_searchterm',
                            value: e.target.value,
                          },
                        });
                      }}
                    />

                   {!this.props.searchComponent.state.collectionLoaded && (
                     <><MDBSpinner small /></>
                   )}
                  </div>
                </div>
            </MDBCol>
          </MDBRow>
      </div>
    )
  }

}

export default VacationFilter;
