import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert,
  MDBSwitch
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';


import ProductActions from "./actions/ProductActions";

import moment from 'moment';
import 'moment/locale/de';

import Select from 'react-select'

import { Modal } from 'antd';


class ProductForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      modal: false,
      entity: this.props.entity,
      data: {
        title: (this.props.entity && this.props.entity.title[0]) ? this.props.entity.title[0].value : '',
        body: (this.props.entity && this.props.entity.body[0]) ? this.props.entity.body[0].value : '',
        field_price: (this.props.entity && this.props.entity.field_price[0]) ? this.props.entity.field_price[0].value : 0,
        field_tax_rule: (this.props.entity && this.props.entity.field_tax_rule[0]) ? this.props.entity.field_tax_rule[0].target_id : false,
        field_product_category: (this.props.entity && this.props.entity.field_product_category[0]) ? this.props.entity.field_product_category[0].target_id : false,
        field_upc: (this.props.entity && this.props.entity.field_upc[0]) ? this.props.entity.field_upc[0].value : '',
        field_image: (this.props.entity && this.props.entity.field_image[0]) ? this.props.entity.field_image[0].target_id : false,
        field_allow_subscription: (this.props.entity && this.props.entity.field_allow_subscription[0]) ? this.props.entity.field_allow_subscription[0].value : 0,
        field_allow_giftcard: (this.props.entity && this.props.entity.field_allow_giftcard[0]) ? this.props.entity.field_allow_giftcard[0].value : 0,
        field_is_favorite: (this.props.entity && this.props.entity.field_is_favorite[0]) ? this.props.entity.field_is_favorite[0].value : 0,
        field_visible: (this.props.entity && this.props.entity.field_visible[0]) ? this.props.entity.field_visible[0].value : 0,
        field_allow_appointment: (this.props.entity && this.props.entity.field_allow_appointment[0]) ? this.props.entity.field_allow_appointment[0].value : 0,
        field_duration: (this.props.entity && this.props.entity.field_duration[0]) ? this.props.entity.field_duration[0].value : 0,
      }
    }


    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onInputChange = this._onInputChange.bind(this);


  }


  componentDidMount() {

    var component = this;

    var taxRulesData = this.props.searchComponent.state.taxRulesData;
    var taxRulesDataFilter = new Array();
    if(taxRulesData) {
      Object.entries(taxRulesData).forEach(function(item, key){
        var optionItem = {
          label: item[1].name[0].value,
          value: item[1].tid[0].value,
        }
        taxRulesDataFilter.push(optionItem);
        //console.log(item);
      });
    }

    var productCategoryData = this.props.searchComponent.state.productCategoryData;
    console.log(productCategoryData);
    var productCategoryDataFilter = new Array();
    if(productCategoryData) {
      Object.entries(productCategoryData).forEach(function(item, key){
          //console.log(item);
        var optionItem = {
          label: item[1].label,
          value: item[1].value
        }
        productCategoryDataFilter.push(optionItem);
        //console.log(item);
      });
    }

    this.setState(prevState => ({
      productCategoryDataFilter: productCategoryDataFilter,
      taxRulesDataFilter: taxRulesDataFilter
    }))


  }


  handleSwitchChange = nr => () => {

    var name = nr;
    console.log(name);

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: !prevState.data[name]  // update the value of specific key
      }
    }))


  }


  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      data: this.state.data
    }

    if(!this.state.entity) {
      ProductActions._post(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadProductCollection();
        });
    } else {
      values.nid = this.state.entity.nid[0].value;
      ProductActions._patch(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadProductCollection();
        });
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      data: {
        title: '',
        body: '',
        field_image: null,
        field_price: 0,
        field_tax_rule: null,
        field_upc: '',
        field_allow_subscription: 0,
        field_allow_giftcard: 0,
        field_is_favorite: 0,
        field_visible: 0,
        field_allow_appointment: 0
      },
      taxRulesData: this.props.searchComponent.taxRulesData,
      productCategoryData: this.props.searchComponent.productCategoryData,
      nid: false
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onInputChange(e) {

    var name = e.target.name;
    var value = e.target.value;

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }



  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }
    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))
  }

  _onChangeHandleFile = (file) => {
    var component = this;

    this.setState({
      file: file
    });

    var extension = file.name.split('.').pop().toLowerCase();
    //console.log(extension);

    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         ProductActions._uploadFile(uint8, extension).then(response => {

           var name = 'field_image';

           component.setState(prevState => ({
             data: {                   // object that we want to update
                 ...prevState.data,    // keep all other key-value pairs
                 [name]: response.data.fid[0].value  // update the value of specific key
             }
           }))


         });
    }, false);


    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        fileUrl: URL.createObjectURL(file)
      });
    }
  }

  render() {
    let component = this;

    var defaultTaxRuleValue = false;

    if(this.state.entity && this.state.taxRulesDataFilter) {
      if(this.state.taxRulesDataFilter.find(x => x.value === this.state.data.field_tax_rule )) {
        defaultTaxRuleValue = { label: this.state.taxRulesDataFilter.find(x => x.value === this.state.data.field_tax_rule).label, value: this.state.data.field_tax_rule }
      }
    }






    var defaultProductCategoryValue = false;

    if(this.state.entity && this.state.productCategoryDataFilter) {
      if(this.state.productCategoryDataFilter.find(x => x.value === this.state.data.field_product_category)) {
        defaultProductCategoryValue = { label: this.state.productCategoryDataFilter.find(x => x.value === this.state.data.field_product_category).label, value: this.state.data.field_product_category}
      }
    }

    return (

      <>




        {!this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neues Produkt erfassen</MDBBtn>
        }

        {this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

        <Modal destroyOnClose={true} title="Formular" visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
            <form id="product-add-form" onSubmit={this._onSubmit}>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    outline
                    name='title'
                    label='Titel'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    onChange={this._onInputChange}
                    value={this.state.data.title}
                  />
                </MDBCol>
              </MDBRow>


              <MDBRow className="md-form md-outline">
                <MDBCol>

                  <Select
                    ref={this.taxRuleFilterRef}
                    required
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder="Steuersatz"
                    options={this.state.taxRulesDataFilter}
                    defaultValue={defaultTaxRuleValue}
                    isClearable={true}
                    onChange={(value) => {

                      if(value) {
                        value = value.value;
                      } else {
                        value = '';
                      }

                      this._onSelectChange({
                        target: {
                          name: 'field_tax_rule',
                          value: value,
                        },
                      });
                    }}
                  />


                </MDBCol>
                <MDBCol>

                  <Select
                    ref={this.productCategoryFilterRef}
                    required
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder="Kategorie"
                    options={this.state.productCategoryDataFilter}
                    defaultValue={defaultProductCategoryValue}
                    isClearable={true}
                    onChange={(value) => {

                      if(value) {
                        value = value.value;
                      } else {
                        value = '';
                      }

                      this._onSelectChange({
                        target: {
                          name: 'field_product_category',
                          value: value,
                        },
                      });
                    }}
                  />


                </MDBCol>
              </MDBRow>

              <MDBRow >
                <MDBCol>
                  <MDBInput
                    outline
                    name='field_price'
                    label='Preis'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    onChange={this._onInputChange}
                    value={this.state.data.field_price}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <MDBInput
                    outline
                    name='body'
                    label='Beschreibung'
                    type="textarea"
                    group
                    error='wrong'
                    success='right'
                    required
                    onChange={this._onInputChange}
                    value={this.state.data.body}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                    <MDBFileupload
                        maxFileSize="16M"
                        ref={this.fileInputRef}
                        getValue={this._onChangeHandleFile}
                        />

                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol>
                  <label><strong>Abofähig</strong></label>
                  <MDBSwitch labelLeft="Nein" labelRight="Ja" checked={this.state.data.field_allow_subscription} onChange={this.handleSwitchChange('field_allow_subscription')} />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <label><strong>Gutscheinfähig</strong></label>
                  <MDBSwitch labelLeft="Nein" labelRight="Ja" checked={this.state.data.field_allow_giftcard} onChange={this.handleSwitchChange('field_allow_giftcard')} />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <label><strong>Favorit</strong></label>
                  <MDBSwitch labelLeft="Nein" labelRight="Ja" checked={this.state.data.field_is_favorite} onChange={this.handleSwitchChange('field_is_favorite')} />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <label><strong>Terminfähig</strong></label>
                  <MDBSwitch labelLeft="Nein" labelRight="Ja" checked={this.state.data.field_allow_appointment} onChange={this.handleSwitchChange('field_allow_appointment')} />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <label><strong>Sichtbar</strong></label>
                  <MDBSwitch labelLeft="Nein" labelRight="Ja" checked={this.state.data.field_visible} onChange={this.handleSwitchChange('field_visible')} />
                </MDBCol>
              </MDBRow>


              {this.state.data.field_allow_appointment === true &&
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      outline
                      name='field_duration'
                      label='Zeit in Minuten'
                      type="text"
                      group
                      error='wrong'
                      success='right'
                      required
                      onChange={this._onInputChange}
                      value={this.state.data.field_duration}
                    />
                  </MDBCol>
                </MDBRow>
              }



              <MDBRow>
                <MDBCol>
                  <div className="form-action-bar">
                    {!this.state.entity &&
                      <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2 hide"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                    }

                    <MDBBtn color="primary" type="submit" disabled={((this.state.hasSubmitted) || (this.state.field_channel == 29 && !this.state.field_selected_recommendation_customer)) ? true : false}><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>

                  </div>
                </MDBCol>
              </MDBRow>

            </form>
        </Modal>
      </>


    );
  }
}

export default ProductForm;
