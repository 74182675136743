import { Component } from 'react';

class RolesToSelect extends Component {
  constructor(props) {
    super(props);
  }

  static convertToSelect(roles) {
    let rolesFilter = new Array();
    if(roles) {
      Object.entries(roles).forEach(function(item, key){
        let optionItem = {
          label: item[1].label,
          value: item[1].id
        }
        rolesFilter.push(optionItem);
        //console.log(item);
      });

      return rolesFilter;
    }
  }

  static getSelectedValue(rolesFilter, roles) {
    let defaultRoleValue = new Array();
    Object.entries(roles).forEach(function(item, key){
      if(rolesFilter.find(x => x.value === item[1])) {
        var selectedItem = { label: rolesFilter.find(x => x.value === item[1]).label, value: item[1].id };
        defaultRoleValue.push(selectedItem);
      }
    })

    return defaultRoleValue;
  }
}

export default RolesToSelect;
