import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBStepper,
  MDBStep,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBContainer
} from 'mdbreact';


import EvaluationDateStep from './EvaluationDateStep';
import EvaluationLocationStep from './EvaluationLocationStep';
import EvaluationTypeStep from './EvaluationTypeStep';
import EvaluationViewStep from './EvaluationViewStep';

import moment from 'moment-timezone';

import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'; // or 'rsuite/dist/styles/rsuite-default.css'

import * as dateFns from "date-fns";

/** import config **/
import config from '../../../config';

/** import styles **/
import '../../../assets/css/sections/evaluation.css';


class EvaluationOverview extends Component {

  constructor(props) {
    super(props);

    var fromDate = new Date(new Date().setHours('00','00','00'));
    var toDate = new Date(new Date().setHours('23','59','50'));

    this.state = {
      formActivePanel3: 1,
      formActivePanel1Changed: false,
      filters: {
        field_location: localStorage.getItem('selectedLocation'),
        field_from: fromDate.toJSON(),
        field_to: toDate.toJSON(),
        field_ignore_location: false,
        field_report_type: 'day'
      },
    }

    this.swapFormActive = this.swapFormActive.bind(this);
    this.handleNextPrevClick = this.handleNextPrevClick.bind(this);
    this.handleSubmission = this.handleSubmission.bind(this);
    this.calculateAutofocus = this.calculateAutofocus.bind(this);

    this._updateSearchFilter = this._updateSearchFilter.bind(this);


    this.sectionRef = React.createRef();
  }


  swapFormActive = (a) => (param) => (e) => {
    this.setState({
      ['formActivePanel' + a]: param,
      ['formActivePanel' + a + 'Changed']: true
    });
  }

  handleNextPrevClick = (a) => (param) => (e) => {
    this.setState({
      ['formActivePanel' + a]: param,
      ['formActivePanel' + a + 'Changed']: true
    });
  }

  handleSubmission = () => {
    alert('Form submitted!');
  }

  calculateAutofocus = (a) => {
    if (this.state['formActivePanel' + a + 'Changed']) {
    return true
    }
  }

  _updateSearchFilter(filters) {

    var component = this;

    //filters['field_from'] = new Date(filters['field_from']);
    //filters['field_to'] = new Date(filters['field_to']);

    //console.log(filters);
    component.setState({
        filters: filters,
    }, () => {

      var evaluationFilter = component.state;
      localStorage.setItem('evaluationFilter', JSON.stringify(evaluationFilter));
    });
  }



  render() {
    return (
      <section id="evaluation-overview" ref={this.sectionRef}>
        <MDBCard className="card-evaluation">
          <MDBCardBody>
            <MDBCardTitle>Auswertungen</MDBCardTitle>
            <MDBCardText tag="div">

              <MDBContainer fluid>
                <MDBStepper vertical>
                  <MDBStep>
                    <a href="#!">
                      <span className="circle">1</span>
                      <span className="label">Zeitraum</span>
                    </a>
                    {this.state.formActivePanel3 === 1 && (
                      <div className="step-content grey lighten-4">
                        <EvaluationDateStep stepper={this} />
                        <hr />
                        <div className="stepper-navigation">
                          <MDBBtn color="primary" onClick={this.swapFormActive(3)(2)}>
                            <MDBIcon icon="chevron-right" /> weiter
                          </MDBBtn>
                        </div>
                      </div>
                    )}

                  </MDBStep>
                  <MDBStep>
                    <a href="#!">
                      <span className="circle">2</span>
                      <span className="label">Standort</span>
                    </a>
                    {this.state.formActivePanel3 === 2 && (
                      <div className="step-content grey lighten-4">
                        <EvaluationLocationStep stepper={this} />
                        <hr />
                        <div className="stepper-navigation">
                          <MDBBtn color="primary" onClick={this.swapFormActive(3)(1)}>
                            <MDBIcon icon="chevron-left" /> zurück
                          </MDBBtn>
                          <MDBBtn color="primary" onClick={this.swapFormActive(3)(3)}>
                            <MDBIcon icon="chevron-right" /> weiter
                          </MDBBtn>
                        </div>
                      </div>
                    )}

                  </MDBStep>
                  <MDBStep>
                    <a href="#!">
                      <span className="circle">
                        3
                      </span>
                      <span className="label">Berichtsart</span>
                    </a>
                    {this.state.formActivePanel3 === 3 && (
                      <div className="step-content grey lighten-4">
                        <EvaluationTypeStep stepper={this} />
                        <hr />
                        <div className="stepper-navigation">
                          <MDBBtn color="primary" onClick={this.swapFormActive(3)(2)}>
                            <MDBIcon icon="chevron-left" /> zurück
                          </MDBBtn>
                          <MDBBtn color="primary" onClick={this.swapFormActive(3)(4)}>
                            <MDBIcon icon="chevron-right" /> weiter
                          </MDBBtn>
                        </div>
                      </div>
                    )}
                  </MDBStep>
                  <MDBStep>
                    <a href="#!">
                      <span className="circle">4</span>
                      <span className="label">Ansicht</span>
                    </a>
                    {this.state.formActivePanel3 === 4 && (
                      <div className="step-content grey lighten-4">
                        <EvaluationViewStep stepper={this} />
                        <hr />
                        <div className="stepper-navigation">
                          <MDBBtn color="primary" onClick={this.swapFormActive(3)(3)}>
                            <MDBIcon icon="chevron-left" /> zurück
                          </MDBBtn>
                        </div>
                      </div>
                    )}
                  </MDBStep>

                </MDBStepper>


              </MDBContainer>




            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

export default EvaluationOverview;
