import React, {
  useState,
  useEffect
} from 'react';

import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBAvatar,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBTable,
  MDBTableHead,
  MDBTableBody
} from 'mdbreact';



import Image from "../image/Image";

import NumberFormat from 'react-number-format';

import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../helpers/PermissionHelper";



class EmployeeHistory extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activeItem: "1"
    };

    this._onToggle = this._onToggle.bind(this);
  }

  _onToggle = tab => e => {
        if (this.state.activeItem !== tab) {
          this.setState({
            activeItem: tab
          });
        }
      };


  render() {
    //console.log(this.props);
    return (
      <>
        <div id="employee-history" className="text-left">
          <h3>Mitarbeiter Historie</h3>
          <MDBNav className="nav-tabs">
            <MDBNavItem>
              <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this._onToggle("1")} role="tab" >
                Kunden
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this._onToggle("2")} role="tab" >
                Behandlungen
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this._onToggle("3")} role="tab" >
                Sitzungen
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent activeItem={this.state.activeItem} >
            <MDBTabPane tabId="1" role="tabpanel">

            </MDBTabPane>
            <MDBTabPane tabId="2" role="tabpanel">

            </MDBTabPane>
            <MDBTabPane tabId="3" role="tabpanel">

            </MDBTabPane>
          </MDBTabContent>
        </div>
      </>
    );
  }
}

export default EmployeeHistory;
