import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';

import PermissionHelper from "../../../helpers/PermissionHelper";


import Select from 'react-select'

import LimitSelect from "../misc/LimitSelect";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';

class EmployeeFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    }


     this.statusOptions = [
      { value: '1', label: 'Aktiv' },
      { value: '0', label: 'Inaktiv' },
    ]

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onDirectionChange = this._onDirectionChange.bind(this);
  }

  componentDidMount() {
    this.setState(prevState => ({
        loaded: true
    }));
  }

  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onDirectionChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this._updateSearchFilter(filters);
  }

  _onInputChange(e) {
    //console.log(e);
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _updateSearchFilter(filters) {

    var component = this;

    //console.log(filters);
    component.props.searchComponent.setState({
        filters: filters,
        collectionLoaded: false,
        page: 0
    }, () => {
        var employeeFilter = component.props.searchComponent.state;
        localStorage.setItem('employeeFilter', JSON.stringify(employeeFilter));
        component.props.searchComponent.loadEmployeeCollection();
    });
  }


  render() {
    let component = this;
    return (
      <div className="filter-card">

        <MDBRow>


          {false &&
          <MDBCol md="3" xs="12">

            <Select
              required
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Standort"
              options={this.props.searchComponent.state.locationsFilter}
              isClearable={true}
              onChange={(value) => {

                if(value) {
                  value = value.value;
                } else {
                  value = '';
                }

                this._onSelectChange({
                  target: {
                    name: 'field_location',
                    value: value,
                  },
                });
              }}
            />

          </MDBCol>
          }


          <MDBCol md="3" xs="12">

            <Select
              required
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Rollen"
              options={this.props.searchComponent.state.rolesFilter}
              isClearable={true}
              onChange={(value) => {

                if(value) {
                  value = value.value;
                } else {
                  value = '';
                }

                this._onSelectChange({
                  target: {
                    name: 'field_role',
                    value: value,
                  },
                });
              }}
            />


          </MDBCol>


          {PermissionHelper._hasPermission('show inactive users') === true &&
            <MDBCol md="1" xs="12">
              <Select
                required
                className="react-select"
                classNamePrefix="react-select"
                placeholder="Status"
                  options={this.statusOptions }
                isClearable={true}
                onChange={(value) => {

                  if(value) {
                    value = value.value;
                  } else {
                    value = '';
                  }

                  this._onSelectChange({
                    target: {
                      name: 'field_status',
                      value: value,
                    },
                  });
                }}
              />
            </MDBCol>
          }


          <MDBCol md="5" xs="12" className="d-flex col-sort">

            <LimitSelect onSelectChange={this._onSelectChange} />

            <Select
              required
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Sortierung"
              options={this.props.searchComponent.state.sortOptions}
              isClearable={true}
              onChange={(value) => {

                if(value) {
                  value = value.value;
                } else {
                  value = '';
                }

                this._onSelectChange({
                  target: {
                    name: 'field_sort',
                    value: value,
                  },
                });
              }}
            />


          <MDBBtn flat size="lg" color="primary" onClick={(e) => {
              this._onDirectionChange({
                target: {
                  name: 'field_sort_direction',
                  value: (this.props.searchComponent.state.filters.field_sort_direction == 'asc') ? "desc" : "asc",
                },
              });
          }}>
          {this.props.searchComponent.state.filters.field_sort_direction == 'asc' &&
            <MDBIcon icon="sort-alpha-down-alt" />
          }

          {this.props.searchComponent.state.filters.field_sort_direction == 'desc' &&
            <MDBIcon icon="sort-alpha-up-alt" />
          }

          </MDBBtn>

          </MDBCol>



        </MDBRow>
        <hr />
          <MDBRow>
            <MDBCol>
                <div className="sui-search-box">
                  <div className="sui-search-box__wrapper">
                    <DebounceInput
                      minLength={2}
                      debounceTimeout={300}
                      id="field_searchterm"
                      name="field_searchterm"
                      placeholder="Mitarbeiternummer, E-Mail, Name"
                      className="sui-search-box__text-input "
                      onChange={(e) => {
                        this._onInputChange({
                          target: {
                            name: 'field_searchterm',
                            value: e.target.value,
                          },
                        });
                      }}
                    />

                   {!this.props.searchComponent.state.collectionLoaded && (
                     <><MDBSpinner small red /></>
                   )}
                  </div>
                </div>
            </MDBCol>
          </MDBRow>
      </div>
    )
  }

}

export default EmployeeFilter;
