import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";


import CategoryActions from "./actions/CategoryActions";

import moment from 'moment';
import 'moment/locale/de';


class CategoryForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nid: false,
      field_customer: this.props.nid,
      field_card_secret_key: '',
      loaded: true,
      modal: false,
    }


    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
  }

  _onHandleScan = data => {
    if (data) {
      this.setState({
        field_card_secret_key: data,
      })
    }
  }

  _onHandleError = err => {
    console.error(err)
  }


  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      nid: this.state.nid,
      field_customer: this.state.field_customer,
      field_card_secret_key: this.state.field_card_secret_key,
    }

    if(!this.state.nid) {
      CategoryActions._post(values)
        .then(response => {
          component.props.searchComponent.loadCategoryCollection();
          component._onFormReset();
          component._onToggleModal();
        });
    } else {
      CategoryActions._patch(values)
        .then(response => {
          component.props.searchComponent.loadCategoryCollection();
          component._onFormReset();
          component._onToggleModal();
        });
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      field_card_secret_key: '',
      nid: false
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    let component = this;
    return (

      <>
      <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Category erfassen</MDBBtn>
      <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Neuen Category erfassen</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className='mt-0'>
            <form id="category-add-form" onSubmit={this._onSubmit}>
              hello form
            </form>
          </MDBCard>
        </MDBModalBody>
      </MDBModal>
      </>


    );
  }
}

export default CategoryForm;
