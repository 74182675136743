import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';

import NewsSearch from "./NewsSearch";

/** import config **/
import config from '../../../config';

/** import styles **/
import '../../../assets/css/sections/news.css';


class NewsOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }

    this.sectionRef = React.createRef();
  }

  render() {
    return (
      <section id="news-overview" ref={this.sectionRef}>
        <MDBCard className="card-news">
          <MDBCardBody>
            <MDBCardTitle>News</MDBCardTitle>
            <MDBCardText tag="div">
              <NewsSearch overviewComponent={this} />
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

export default NewsOverview;
