import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';


import OrderActions from "./actions/OrderActions";

import moment from 'moment';
import 'moment/locale/de';


class OrderForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      modal: false,
      entity: this.props.entity,
      data: {
        field_cash_register_session: JSON.parse(localStorage.getItem('selectedCashRegisterSession')).node.nid[0].value
      }
    }


    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
  }


  componentDidMount() {
    var component = this;
  }


  _onSubmit(e) {
    var component = this;
    e.preventDefault();


    let values = {
      data: this.state.data
    }

    if(!this.state.entity) {
      OrderActions._post(values)
        .then(response => {
          console.log(response.data.order);
          //component._onFormReset();
          //component._onToggleModal();

          if(component.props.searchComponent.props.checkout) {
            component.props.searchComponent.props.checkout._onStartCheckout(response.data.order);
          } else {
            component.props.searchComponent.loadOrderCollection();
          }





        });
    } else {
      values.nid = this.state.entity.nid[0].value;
      /*
      OrderActions._patch(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadOrderCollection();
        });*/
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      data: {
        field_location: localStorage.getItem('selectedLocation')
      },
      nid: false
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  render() {
    let component = this;
    return (

      <>




        {!this.props.entity &&
          <MDBBtn onClick={this._onSubmit} color="primary"><MDBIcon icon="plus mr-2" /> Neue Bestellung starten</MDBBtn>
        }

        {this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

      <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Neuen Bestellung starten</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className='mt-0 text-left'>
            <form id="order-add-form" onSubmit={this._onSubmit}>

              <MDBRow>
                <MDBCol>
                  {!this.state.entity &&
                    <MDBBtn color="primary" onClick={this._onFormReset} className="mr-2 hide"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                  }
                  <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                </MDBCol>
              </MDBRow>

            </form>
          </MDBCard>
        </MDBModalBody>
      </MDBModal>
      </>


    );
  }
}

export default OrderForm;
