import React, { Component } from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBRow,
  MDBListGroup,
  MDBListGroupItem,
  MDBBadge,
  MDBIcon,
  MDBCardTitle,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBSpinner
} from 'mdbreact';
import { Bar, Pie, Line } from 'react-chartjs-2';

class WeeklySalesStats extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeItem: 0
    }

    this.toggle = this.toggle.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
 }


  componentDidMount() {
    var component = this;
    if(component.props.dashboard.state.loaded) {
      this.setState({
        activeItem: 0
      });
    }
  }

  formatCurrency(value) {
    return value;
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(value);
  }

  toggle = tab => () => {
      if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
      }
    }

    render(){

      var component = this;


        const barChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
            xAxes: [{
                barPercentage: 1,
                gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
                }
            }],
            yAxes: [{
                gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)'
                },
                ticks: {
                beginAtZero: true
                }
            }]
            }
        }

        return (
            <MDBRow className={`mb-4 ${(component.props.dashboard.state.loaded && Object.entries(component.props.dashboard.state.result.week).length > 6) ? "hide" : ""}`}>

              <hr />

                <MDBCol md="12"className="mb-12">
                    <MDBCard className="mb-12">
                      <MDBCardBody>
                        <MDBCardTitle>Wochenverlauf</MDBCardTitle>
                      </MDBCardBody>
                        <MDBCardBody>

                          {!component.props.dashboard.state.loaded &&
                            <MDBSpinner red />
                          }


                          <MDBNav tabs>
                            {component.props.dashboard.state.loaded && Object.entries(component.props.dashboard.state.result.week).length < 8 && Object.entries(component.props.dashboard.state.result.week).length > 0 && (
                              Object.entries(component.props.dashboard.state.result.week).map(function(row, i){

                                console.log(row);

                                return (
                                  <MDBNavItem>
                                    <MDBNavLink
                                      link
                                      to="#"
                                      active={component.state.activeItem === i}
                                      onClick={component.toggle(i)}
                                      role="tab"
                                    >
                                    KW {row[0]}
                                    </MDBNavLink>
                                  </MDBNavItem>
                                )

                              })
                              )}
                          </MDBNav>
                          <MDBTabContent
                            className="card"
                            activeItem={this.state.activeItem}
                          >

                          {component.props.dashboard.state.loaded && Object.entries(component.props.dashboard.state.result.week).length < 8 && (
                            Object.entries(component.props.dashboard.state.result.week).map(function(row, j){

                              var dataLine = {
                                labels: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
                                datasets: [
                                  {
                                    label: "Umsätze in CHF",
                                    fill: true,
                                    lineTension: 0.1,
                                    backgroundColor: "#e6eceb",
                                    borderColor: "#63cdd7",
                                    borderCapStyle: "butt",
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: "miter",
                                    pointBorderColor: "rgb(205, 130,1 58)",
                                    pointBackgroundColor: "rgb(255, 255, 255)",
                                    pointBorderWidth: 10,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: "rgb(0, 0, 0)",
                                    pointHoverBorderColor: "rgba(220, 220, 220,1)",
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: [component.formatCurrency(row[1][0].sales), component.formatCurrency(row[1][1].sales),component.formatCurrency(row[1][2].sales), component.formatCurrency(row[1][3].sales), component.formatCurrency(row[1][4].sales), component.formatCurrency(row[1][5].sales), component.formatCurrency(row[1][6].sales)]
                                  }
                                ]
                              };

                              var options = {
                                responsive: true,
                                title: {
                    text: 'Title',
                    display: false,
                    fontSize: 24
                },
                scales: {
                    xAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Woche'
                        }
                    }],
                    yAxes: [{
                      display: true,
                      ticks: {
                          beginAtZero: true
                      },
                      scaleLabel: {
                          display: true,
                          labelString: 'CHF'
                      }
                    }]
                },


                plugins: {
      filler: {
        propagate: false,
      },
      title: {
        display: true,
        text: (ctx) => 'Fill: ' + ctx.chart.data.datasets[0].fill
      }
    },
    interaction: {
      intersect: false,
    }

                              }



                              return (
                                <MDBTabPane tabId={j} role="tabpanel">
                                  <p className="mt-2">
                                    {component.state.activeItem == j &&
                                    <Line data={dataLine} height="auto" options={options} />
                                    }
                                  </p>
                                </MDBTabPane>
                              )
                            })
                          )}


                        </MDBTabContent>

                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        )
    }
}

export default WeeklySalesStats;
