import React, { Component } from 'react';
import {
  MDBCard,
  MDBSpinner,
  MDBLink,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBAnimation,
  MDBBadge
} from 'mdbreact';
import PermissionHelper from "../../../helpers/PermissionHelper";
import { history } from '../../../helpers/history';

import NumberFormat from 'react-number-format';

import CashRegisterActions from "./actions/CashRegisterActions";


import CashRegisterSessionSearch from "../cashregistersession/CashRegisterSessionSearch";
import TerminalSearch from "../terminal/TerminalSearch";


import moment from 'moment';
import 'moment/locale/de';

/** import styles **/
import '../../../assets/css/sections/cashregister.css';



class CashRegisterDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      entity: null,
      nid: this.props.match.params.id,
      activeItem: "1",
    };

    this._onDelete = this._onDelete.bind(this);
    this._onTabToggle = this._onTabToggle.bind(this);
  }

  componentDidMount() {
    CashRegisterActions._get(this.props.match.params.id).then(response => {
      //console.log(response);
      this.setState({
        entity: response.data.entity,
        loaded: true
      });
    });
  }

  _onTabToggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };



  _onDelete() {
    const r = window.confirm("Löschen Bestätigen");
    if(r === true){
      this.setState({
        loaded: false
      });
      CashRegisterActions._delete(this.props.match.params.id).then(response => {
        history.push('/customer');
      });
    }
  }

  render() {

    let component = this;

    return (
      <section id="cashregister-detail">
        <MDBCard>
          <MDBCardBody tag="div">

            <MDBCardTitle>

              <MDBRow>
                <MDBCol size="6" className="">
                    Kasse: #
                    {this.state.entity &&
                      <span>{this.state.entity.field_cash_register_number[0].value}</span>
                    }
                </MDBCol>
                <MDBCol size="6">

                  {this.state.entity &&
                  <div className="action-bar">
                    <MDBNavLink to={"/cashregister"} className="mr-2">
                      <MDBBtn size="sm" color="info">
                        <MDBIcon icon="chevron-left" />
                      </MDBBtn>
                    </MDBNavLink>
                  </div>
                  }

                </MDBCol>
              </MDBRow>



            </MDBCardTitle>







            {!this.state.loaded &&
              <div className="text-center">
                <MDBSpinner red />
              </div>
            }


            {this.state.loaded &&
              <MDBCardText tag="div">


                  <div className="row">
                    <div className="col-md-12">



                      <div className="cashregister-tabs">
                        <MDBNav tabs>
                          <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this._onTabToggle("1")} role="tab">Sitzungen</MDBNavLink>
                          </MDBNavItem>
                          <MDBNavItem>
                            <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this._onTabToggle("2")} role="tab">Terminals</MDBNavLink>
                          </MDBNavItem>
                        </MDBNav>
                        <MDBTabContent className="card-no" activeItem={this.state.activeItem}>
                          <MDBTabPane tabId="1" role="tabpanel">
                            {this.state.entity &&
                              <><CashRegisterSessionSearch nid={this.state.entity.nid[0].value} /></>
                            }
                          </MDBTabPane>
                          <MDBTabPane tabId="2" role="tabpanel">
                            {this.state.entity &&
                              <><TerminalSearch nid={this.state.entity.nid[0].value} /></>
                            }
                          </MDBTabPane>
                        </MDBTabContent>
                      </div>



                    </div>
                  </div>
              </MDBCardText>
            }




          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

//export default Customers;
export default CashRegisterDetail;
