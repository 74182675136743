import React, { Component } from 'react';
import { connect } from 'react-redux';
import userActions from '../actions/userActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import {
  MDBIcon,
  MDBAvatar,
  MDBPopover,
  MDBBtn,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBNavLink
} from 'mdbreact';

class TopNavigation extends Component {
  constructor(props) {
    super(props);

    var currentLanguage = localStorage.getItem('lang');

    this.state = {
      collapse: false,
      user: this.props.user,
      selectedLocation: localStorage.getItem('selectedLocation'),
      locationItems: [],
      languageOptions: [
        {
          text: "DE",
          value: "de",
          checked: currentLanguage === 'de' ? true :false
        },
        {
          text: "EN",
          value: "en",
          checked: currentLanguage === 'en' ? true :false
        }
      ]
    };

    this._onHandleToggle = this._onHandleToggle.bind(this);
    this._onLogout = this._onLogout.bind(this);
    this._onLanguageChange = this._onLanguageChange.bind(this);
    this._onReload = this._onReload.bind(this);
  }


  componentDidMount() {
    var component = this;

    const locationItems = []

    for (const [index, value] of this.props.locations.entries()) {
      //console.log(value);

      if(this.state.selectedLocation == value.nid[0].value) {
        component.setState({
          selectedLocationOption: value.title[0].value
        });
      }

      locationItems.push(<MDBDropdownItem key={'location-' + value.nid[0].value} {...(component.state.selectedLocation ==  value.nid[0].value ? {active: true} : {})} onClick={component._onChangeLocation(value.nid[0].value)}>{value.title[0].value}</MDBDropdownItem>)
    }

    component.setState({
      locationItems: locationItems
    });
  }


  _onHandleToggle() {
    this.props.onSideNavToggleClick();
  }

  _onLogout() {
    this.props.logout();
  }

  _onLanguageChange(value) {
    localStorage.setItem('lang', value[0]);
  }

  _onReload() {
    window.location.reload(false);
  }

  _prepareLocationDropdown() {
    var component = this;
    var locations = this.props.locations;
    var locationOptions = [];
    //console.log(locations);
    var selectedLocationOption = false;

    if(this.props.locations) {
      locations.forEach(function(item){
        var optionItem = {
          label: item.title[0].value,
          value: item.nid[0].value.toString()
        }
        var selectedLocation = localStorage.getItem('selectedLocation');


        if(selectedLocation == item.nid[0].value.toString()) {



          //console.log(item.nid[0].value.toString());

          selectedLocationOption = {
            label: item.title[0].value,
            value: item.nid[0].value.toString()
          }

          //console.log(selectedLocationOption);

          component.setState({
            selectedLocationOption: selectedLocationOption
          });
        }
        locationOptions.push(optionItem);
        //console.log(optionItem);
      });

      this.setState({
        locationOptions: locationOptions,
      });
    }
  }


  _onChangeLocation = param => e => {
    //console.log(param);
      // param is the argument you passed to the function
      // e is the event object that returned

      //console.log(param);
      if(param != this.state.selectedLocation && param && param != '') {
        this.setState({
          selectedLocation: param
        });
        localStorage.setItem('selectedLocation', param);
        window.location.reload();
      }
  };

  render() {

    var component = this;



    return (
      <section id="top-bar">



        <img src="https://www.venus-beauty.ch/themes/venusbeauty/images/logo.svg" class="img-fluid logo" />


        {this.state.user &&
          <div className="avatar">
              {/*
              <MDBAvatar
                  tag="img"
                  src={this.state.user.field_image[0].url}
                  className="rounded-circle img-fluid"
                  alt="Sample avatar"
                />*/}
                <MDBIcon icon="user-circle" />
                <span>{this.state.user.field_firstname[0].value} {this.state.user.field_lastname[0].value}</span>

                  <MDBDropdown size="xs">
                  <MDBDropdownToggle>

                    {this.state.selectedLocationOption && (
                      <span>
                        <MDBIcon icon="globe" /> {this.state.selectedLocationOption}
                      </span>
                    )}


                  </MDBDropdownToggle>
                  <MDBDropdownMenu color="danger" basic>
                    {component.state.locationItems}
                  </MDBDropdownMenu>
                </MDBDropdown>

                <MDBBtn flat onClick={this._onReload} id="reload-btn">
                  <FontAwesomeIcon icon={AllLightIcons.faRedo} />
                </MDBBtn>





          </div>
        }





      </section>
    );
  }
}


function mapState(state) {
    const { authenticationReducer } = state;
    const { user, locations } = authenticationReducer;
    return { user, locations };
}

const actionCreators = {
  logout: userActions.logout
}

const connectedTopNavigation= connect(mapState, actionCreators)((TopNavigation));
export { connectedTopNavigation as TopNavigation };
