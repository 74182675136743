import React, { Component } from 'react';
import '../App.css';
import { SideNavigation } from './SideNavigation';
import { TopNavigation } from './TopNavigation';
import Routes from './Routes';
import { Breadcrumb } from 'antd';
import { Link } from "react-router-dom"

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      windowWidth: 0,
      currentPage: '',
      sideNavToggled: false,
      breakWidth: 1400
    };


    this.handleResize = this.handleResize.bind(this);
    this.toggleSideNav = this.toggleSideNav.bind(this);
  }

  componentDidUpdate(prevProps, nextProps, snapshot) {
  }
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  toggleSideNav(e) {

    this.setState({
      currentPage: e
    });

    if (this.state.windowWidth < this.state.breakWidth) {
      this.setState({
        sideNavToggled: !this.state.sideNavToggled
      });
    }
  };

  render() {
    const dynamicLeftPadding = {
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? '240px' : '0'
    };

    return (
      <div className='app'>




        <div className='white-skin'>
          <SideNavigation
            breakWidth={this.state.breakWidth}
            style={{ transition: 'all .3s' }}
            triggerOpening={this.state.sideNavToggled}
            onLinkClick={(e) => this.toggleSideNav(e)}
          />
        </div>
        <div className='flexible-content white-skin'>
          <TopNavigation
            toggle={this.state.windowWidth < this.state.breakWidth}
            onSideNavToggleClick={this.toggleSideNav}
            routeName={this.state.currentPage}
            className='white-skin'
          />
          <main style={{ ...dynamicLeftPadding, margin: '8rem 6% 6rem' }}>
            <Routes />
          </main>
        </div>
      </div>
    );
  }
}

export default App;
