import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './assets/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import './assets/css/app.css';
import './assets/css/app-v2.css';
import './assets/css/responsive.css';
import './assets/css/libraries.css';
import './assets/css/print.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './helpers/store';
import { history } from './helpers/history';
import ScrollToTop  from './ScrollToTop';
import './i18n';

if (window.location.href.indexOf("stage") === -1 && window.location.href.indexOf("dev") === -1) {
    console.log = function () {};
}

ReactDOM.render(
  <Router history={history}>
    <ScrollToTop />
    <Provider store={store}>
        <App />
    </Provider>
  </Router>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();
