import React, { Component } from 'react';

/** import config **/
import config from '../../../config';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';



import EmployeeSearch from "./EmployeeSearch";



/** import styles **/
import '../../../assets/css/sections/employee.css';


class EmployeeOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }

    this.sectionRef = React.createRef();
  }

  componentDidMount() {
  }

  render() {
    return (
      <section id="employee-overview" ref={this.sectionRef}>
        <MDBCard className="card-employee">
          <MDBCardBody>
            <MDBCardTitle>Mitarbeiter</MDBCardTitle>
            <MDBCardText tag="div">
              <EmployeeSearch overviewComponent={this} />
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

export default EmployeeOverview;
