import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';


import CashRegisterSessionActions from "./actions/CashRegisterSessionActions";

import CashRegisterSessionForm from "./CashRegisterSessionForm";


import PermissionHelper from "../../../helpers/PermissionHelper";

import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';



import moment from 'moment';
import 'moment/locale/de';





class CashRegisterSessionResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onEdit = this._onEdit.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      CashRegisterSessionActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadCashRegisterSessionCollection();
        });
    }
  }

  _onEdit(e) {

  }


  render() {

    console.log(this.props.result);

    return (
      <tr className="cashregistersession-item">
        <td>#{ this.props.result.node.field_cash_register_session_numb[0].value }</td>
        <td>{moment(this.props.result.node.created[0].value).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")}</td>
        <td className="text-right">
          <div className="action-bar">

            <MDBBtn color="info">
              <MDBLink to={'/cashregistersession/' + this.props.result.nid} link>
                <MDBIcon icon="info-circle" />
              </MDBLink>
            </MDBBtn>



            <MDBBtn color="info" onClick={this._onModalToggle}>
                <MDBIcon far icon="file-pdf" />
            </MDBBtn>



            <MDBModal className="text-left" isOpen={this.state.modal} toggle={this._onModalToggle} size="lg">
                    <MDBModalBody>
                      {this.props.result.file &&
                        <>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
                            <div style={{ height: '750px' }}>
                                <Viewer
                                    fileUrl={this.props.result.file}
                                />
                            </div>
                        </Worker>
                        </>
                      }
                      <hr />
                      {this.props.result.node.body[0] &&
                        <span dangerouslySetInnerHTML={{__html: this.props.result.node.body[0].value}} />
                      }

                    </MDBModalBody>
                    <MDBModalFooter>
                      <MDBBtn color="danger" onClick={this._onModalToggle}><MDBIcon icon="times" /> schliesen</MDBBtn>
                    </MDBModalFooter>
                  </MDBModal>


              {PermissionHelper._hasPermission('restful delete CashRegisterSession') === true && false &&
                <>
                  <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
                </>
              }
              {PermissionHelper._hasPermission('restful patch CashRegisterSession') === true && false &&
                <>
                  <CashRegisterSessionForm key={'cashregistersession-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} searchComponent={this.props.searchComponent} />
                </>
              }
          </div>



        </td>
      </tr>
    );
  }
}

export default CashRegisterSessionResultRow;
