import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";


import { Modal } from 'antd';

import MDBFileupload from 'mdb-react-fileupload';

import Newsletter from '../../../assets/images/qrCard.png' // relative path to image

import NewsletterActions from "./actions/NewsletterActions";

import moment from 'moment';
import 'moment/locale/de';

import { Editor } from '@tinymce/tinymce-react';


class NewsletterForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      result: 'No result',
      modal: false,
      entity: this.props.entity,
      data: {
        title: (this.props.entity && this.props.entity.title[0]) ? this.props.entity.title[0].value : '',
        body: (this.props.entity && this.props.entity.body[0]) ? this.props.entity.body[0].value : '',
        field_location: [localStorage.getItem('selectedLocation')],
      }
    }

    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onHandleEditorChange = this._onHandleEditorChange.bind(this);
  }

  componentDidMount() {
    var component = this;
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = this.state.data;

    if(!this.state.entity) {
      NewsletterActions._post(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadNewsletterCollection();
        }).catch(error => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadNewsletterCollection();
        });
    } else {
      values.nid = this.state.entity.nid[0].value;
      NewsletterActions._patch(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadNewsletterCollection();
        });
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      data: {
        body: '',
        title: '',
        field_location: [localStorage.getItem('selectedLocation')],
      },
      nid: false
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onInputChange(e) {

    var name = e.target.name;
    var value = e.target.value;

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }

    //console.log(e.target.value);

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }



  _onHandleEditorChange = (content, editor) => {
     //console.log('Content was updated:', content);
     this.setState(prevState => ({
       data: {                   // object that we want to update
           ...prevState.data,    // keep all other key-value pairs
           body: content  // update the value of specific key
       }
     }))
   }


  render() {
    let component = this;
    return (

      <>




        {!this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Newsletter erfassen</MDBBtn>
        }

        {this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
        }

        <Modal title="Newsletter" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

            <form id="newsletter-add-form" onSubmit={this._onSubmit}>

              <MDBRow>
                <MDBCol>
                  <MDBInput outline label="Titel" name="title" id="title" onChange={this._onInputChange} value={this.state.title} required />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput type="textarea" outline label="Nachricht" name="body" id="body" onChange={this._onInputChange} value={this.state.title} required />
                </MDBCol>
              </MDBRow>

              <hr />

              <MDBRow>
                <MDBCol>

                  <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                </MDBCol>
              </MDBRow>

            </form>
      </Modal>
      </>


    );
  }
}

export default NewsletterForm;
