import React, {
  Component
} from 'react';

import NewsletterResultRow from "./NewsletterResultRow";
import Pager from 'react-pager';
import NewsletterFilter from "./NewsletterFilter";
import PermissionHelper from "../../../helpers/PermissionHelper";
import NewsletterActions from "./actions/NewsletterActions";
import NewsletterForm from "./NewsletterForm";
import { Empty } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';


import config from '../../../config';



class NewsletterSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      numbers: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_location: localStorage.getItem('selectedLocation'),
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc'
      },
      sortOptions: [
        {
          checked: true,
          text: 'Geändert',
          value: 'nfd.changed'
        }
      ]
    }

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  componentDidMount() {
    this.loadNewsletterCollection();
  }

  loadNewsletterCollection() {

      var component = this;

      component.setState({
        collectionLoaded: false
      });


      /** build filter query **/
      NewsletterActions._get('All', this.state.filters, this.state.page).then(response => {
        //  console.log(response.data.entity);
        //console.log(response.data.result);


        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        var numbers = [];
        if(response.data.numbers) {
          numbers = Object.entries(response.data.numbers);
        }




        component.setState({
          result: result,
          numbers: numbers,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });

      });
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadNewsletterCollection();
    });
  }

  render() {
    let component = this;
    return (
      <>

        {!this.state.loaded &&
          <div className="text-center">
            <MDBSpinner key={'newsletter-loader'} red />
          </div>
        }

        {component.state.loaded && (
          <>

          {PermissionHelper._hasPermission('restful post Newsletter') === true &&
            <>
              <NewsletterForm searchComponent={this} ref={this.formRef} />
              <hr />
            </>
          }


            <NewsletterFilter searchComponent={component} />
            <hr />
            { component.state.result.length > 0 && (
              <MDBTable responsive hover striped className="text-left">
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Newsletter Nr #
                    </th>
                    <th className='th-lg'>
                      Titel
                    </th>
                    <th className='th-lg'>
                      Empfänger
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <NewsletterResultRow result={row[1]}  key={'newsletter-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>
            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}

      </>
    );
  }
}

export default NewsletterSearch;
