import React, { Component } from 'react';

/** import config **/
import config from '../../../config';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';



import TaskSearch from "./TaskSearch";



/** import styles **/
import '../../../assets/css/sections/task.css';


class TaskOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <section id="task-overview">
        <MDBCard className="card-task">
          <MDBCardBody>
            <MDBCardTitle>Aufgaben</MDBCardTitle>
            <MDBCardText tag="div">
              <TaskSearch route={this.props.location.pathname} />
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

export default TaskOverview;
