import React, { Component } from 'react';
import Pager from 'react-pager';
import CashRegisterSessionResultRow from "./CashRegisterSessionResultRow";
import CashRegisterSessionActions from "./actions/CashRegisterSessionActions";
import Image from "../image/Image";
import CashRegisterSessionFilter from "./CashRegisterSessionFilter";
import CashRegisterSessionForm from "./CashRegisterSessionForm";
import PermissionHelper from "../../../helpers/PermissionHelper";
import { Empty } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';


class CashRegisterSessionSearch extends Component {

  constructor(props) {
    super(props);

    var fromDate = new Date(new Date().setHours('00','00','00'));
    var toDate = new Date(new Date().setHours('23','59','50'));

    this.state = {
      loaded: false,
      collectionLoaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_searchterm: null,
        field_cash_register: this.props.nid,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc',
        field_is_completed: 1,
        field_limit: 10,
        field_from: fromDate.toJSON(),
        field_to: toDate.toJSON(),
      },
      sortOptions: [
        {
          label: 'Geändert',
          value: 'nfd.changed'
        },
        {
          label: 'Titel',
          value: 'nfd.title'
        }
      ]
    }


    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadCashRegisterSessionCollection();
    });
  }

  componentDidMount(){



    console.log('componentDidMount');

    let component = this;

    var cashRegisterSessionFilter = JSON.parse(localStorage.getItem('cashregistersessionFilter'));

    if(cashRegisterSessionFilter) {
      cashRegisterSessionFilter.filters.field_from = new Date(cashRegisterSessionFilter.filters.field_from).toJSON();
      cashRegisterSessionFilter.filters.field_to = new Date(cashRegisterSessionFilter.filters.field_to).toJSON();
      cashRegisterSessionFilter.filters.field_cash_register = this.props.nid;
      component.setState({
        filters: cashRegisterSessionFilter.filters
      }, function() {
        //console.log('1');
        //return;
        component.loadCashRegisterSessionCollection();
      });
    } else {
      //console.log('2');
      //return;
      let cashRegisterSessionFilter = component.state.filters;

      cashRegisterSessionFilter.field_cash_register = this.props.nid;
      var fromDate = new Date(new Date().setHours('00','00','00'));
      var toDate = new Date(new Date().setHours('23','59','50'));
      cashRegisterSessionFilter.field_from = fromDate.toJSON();
      cashRegisterSessionFilter.field_to = toDate.toJSON();

      component.setState({
        filters: cashRegisterSessionFilter
      }, function() {
        //console.log('1');
        //return;
        component.loadCashRegisterSessionCollection();
      });
    }


    //this.loadCashRegisterSessionCollection();
    //console.log(this);
  }

  loadCashRegisterSessionCollection() {


      var component = this;

      component.setState({
        collectionLoaded: false,
      });


      /** build filter query **/

      CashRegisterSessionActions._get('All', this.state.filters, this.state.page).then(response => {

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        component.setState({
          result: result,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });

      });
  }

  render() {
    let component = this;
    return (
      <>



        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'cashregistersession-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

          {

            <>
            <CashRegisterSessionFilter searchComponent={this} /><hr />
            </>

          }

            { component.state.result.length > 0 && (


              <MDBTable responsive hover striped className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                <thead>
                  <tr>
                    <th>Sitzungsnummer</th>
                    <th>erstellt am</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <CashRegisterSessionResultRow result={row[1]}  key={'cashregistersession-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.filters.field_limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default CashRegisterSessionSearch;
