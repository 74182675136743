import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import NewsActions from "./actions/NewsActions";
import NewsForm from "./NewsForm";
import NewsComment from "../comment/CommentSearch.jsx";
import Image from "../image/Image";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';
import { Modal } from 'antd';



class NewsResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this.newsCommentRef = React.createRef();

    this._onDelete = this._onDelete.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      NewsActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadNewsCollection();
        });
    }
  }


  render() {

    console.log(this);

    return (
      <MDBCol xl="3" lg="6" md="6" className="mb-4 news-item">
        <div className="action-bar">
          {PermissionHelper._hasPermission('edit any news content') === true &&
            <>
              <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
              <NewsForm key={'news-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} searchComponent={this.props.searchComponent} locations={this.props.result.locations} category={this.props.result.category}  />
            </>
          }
        </div>
        <img className="img-fluid img-thumbnail" src={this.props.result.thumbnail} />
        <h4 className="font-weight-bold mb-3 mt-3"><strong>{this.props.result.title}</strong></h4>
        <p className="dark-grey-text">{this.props.result.snippet}</p>
        <hr />
        <MDBBtn onClick={this._onModalToggle} color="primary" size="md"><MDBIcon icon="align-left mr-2" /> weiterlesen</MDBBtn>


          <Modal width={600} title="News" destroyOnClose={true} visible={this.state.modal} onOk={this._onModalToggle} onCancel={this._onModalToggle} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

            <MDBRow>
              <MDBCol lg="5" className="overflow-hidden">
                <img className="img-fluid img-thumbnail mb-3" src={this.props.result.image} />
              </MDBCol>
              <MDBCol lg="7">
                <a href="#!" className="green-text">
                  <h6 className="font-weight-bold mb-3">
                    <MDBIcon icon="info-circle mr-2" />
                    {this.props.result.field_news_category}
                  </h6>
                </a>
                <h3 className="font-weight-bold mb-3 p-0"><strong>{this.props.result.title}</strong></h3>
                <p><span dangerouslySetInnerHTML={{__html: this.props.result.body}} /></p>
              </MDBCol>
            </MDBRow>

            <NewsComment ref={this.newsCommentRef} entityId={this.props.result.nid} entityType="node" fieldName="field_news_comment" commentType="news_comment" showImportant={false} />


          </Modal>
      </MDBCol>
    );
  }
}

export default NewsResultRow;
