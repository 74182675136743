import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBFileInput,
  MDBSelect,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBAnimation,
  MDBNavLink,
  MDBCardText,
  MDBLink,
  MDBCollapseHeader,
  MDBCollapse,
  MDBDatePickerV5
} from 'mdbreact';
import VacationQuotaActions from './actions/VacationQuotaActions';
import { history } from '../../../helpers/history';

import Select from 'react-select';
import { Modal } from 'antd';


class VacationQuotaForm extends Component {

  constructor(props) {
    super(props);




    var employeeOptions = this.props.searchComponent.state.employeeFilter;
    var vacationquotaCategoryOptions = this.props.searchComponent.state.vacationquotaCategoryOptions;

    this.state = {
      hasSubmitted: false,
      modal: false,
      nid: this.props.nid,
      field_employee: [this.props.uid],
      field_year: this.props.searchComponent.state.filters.field_year,
      field_quota: this.props.quota,
      employeeOptions: employeeOptions,
    }


    this.employeeSelectRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onClickDelete = this._onClickDelete.bind(this);
  }

  componentDidMount() {
    let component = this;
    //console.log('componentDidMount');


    var employeeFilter = new Array();

    Object.entries(component.state.employeeOptions).forEach(function(item, key){
      //console.log(item);
      var optionItem = {
        text: item[1].text,
        value: item[1].value
      }

      if(item[1].value == component.props.uid) {
        optionItem.checked = true;
      }

      employeeFilter.push(optionItem);
    });

    //console.log(employeeFilter);

    component.setState({
      employeeOptions: employeeFilter,
      employeeSelectComp: <MDBSelect
        options={employeeFilter}
        selected="Mitarbeiter"
        label="Mitarbeiter"
        name="field_employee"
        ref={component.employeeSelectRef}
        id="field_employee"
        getValue={(e) => {
          component._onSelectChange({
            target: {
              name: 'field_employee',
              value: e,
            },
          });
        }}
      />,
    });


  }

  _onToggleModal = () => {
    var component = this;
    this.setState({
      modal: !this.state.modal
    }, component._onToggleModalCallback);
  }

  _onToggleModalCallback = () => {
    var component = this;
    if(this.state.modal) {
      setTimeout(function(){
        //console.log(component.state);
        if(component.state.nid) {
          //component.employeeSelectRef.current.selectOption(component.state.field_employee);
        }
      }, 100);
    } else {
      //component._onFormReset();
    }
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }

    console.log(this);

    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();


    if(component.state.hasSubmitted) {
      return;
    }

    this.setState(prevState => ({
      loaded: false,
      hasSubmitted: true,
    }))




    let values = {
      nid: this.state.nid,
      field_employee: this.state.field_employee,
      field_year: this.state.field_year,
      field_quota: this.state.field_quota,
    }


    if(!this.state.nid) {
      VacationQuotaActions._post(values)
        .then(response => {


          component.setState(prevState => ({
            hasSubmitted: false,
          }))



          if(response.data && response.data.status == 200) {
            component.props.searchComponent.loadVacationCollection();
            component._onFormReset();
            component._onToggleModal();
          }
        });
    } else {
      VacationQuotaActions._patch(values)
        .then(response => {

          component.setState(prevState => ({
            hasSubmitted: false,
          }))

          if(response.data && response.data.status == 200) {
            component.props.searchComponent.loadVacationCollection();
            component._onFormReset();
            component._onToggleModal();
          }
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });

    //console.log(this.state);
  }


  _onFormReset() {

    var component = this;

    this.setState(prevState => ({
      hasSubmitted: false,
      loaded: true,
      nid: false,
      field_employee: '',
      field_year: null,
      field_quota: null,
    }))


    if(component.employeeSelectRef.current) {
      component.employeeSelectRef.current.selectOption();
    }
  }


  _onClickDelete(e) {


    //console.log('_onClickDelete');
    var component = this;
    VacationQuotaActions._delete(this.state.nid)
      .then(response => {
        if(response.data.status == 400) {
          alert(response.data.message);
        } else {
          component.props.searchComponent.loadVacationCollection();
          component._onToggleModal();
          component._onFormReset();
        }
      });

  }

  render() {
    let component = this;
    return (
      <>





      {!this.props.nid &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus" /></MDBBtn>
      }
      {this.props.nid &&
        <MDBBtn onClick={this._onToggleModal} color="secondary"><MDBIcon icon="edit" /></MDBBtn>
      }





      <Modal title="Urlaub" destroyOnClose={true} visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>


              <form onSubmit={this._onSubmit} id="vacationquota-add-form">

                <div className="row hide">
                  <div className="col-sm-12">
                    {this.state.employeeSelectComp}
                  </div>
                </div>

                <div className="row hide">
                  <div className="col-sm-12">
                    <MDBInput outline required type="number" label="Jahr" name="field_year" value={this.props.searchComponent.state.filters.field_year} onChange={this._onInputChange} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <MDBInput outline required type="number" label="Tage" name="field_quota" value={this.state.field_quota} onChange={this._onInputChange} />
                  </div>
                </div>

                <MDBRow>
                  <MDBCol>

                    <div className="form-action-bar">
                      {!this.props.nid &&
                        <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2 hide"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                      }
                      {this.props.nid &&
                        <MDBBtn color="danger" onClick={this._onClickDelete} className="mr-2"><MDBIcon icon="sync mr-2" /> löschen</MDBBtn>
                      }

                      <MDBBtn color="primary" disabled={( this.state.hasSubmitted  ? true : false)} type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                    </div>

                  </MDBCol>
                </MDBRow>

              </form>

            </Modal>
      </>
    );
  }
}

export default VacationQuotaForm;
