import React from 'react';

import i18n from "i18next";
import { withTranslation, initReactI18next } from "react-i18next";

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

import QrReader from 'react-qr-reader'

import EmployeeCardImage from '../../../assets/images/mitarbeiterkarte.png' // relative path to image

import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBInput,
  MDBIcon,
  MDBView,
  MDBBtn,
  MDBAlert,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBFormInline,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSpinner
} from 'mdbreact';
import { connect } from 'react-redux';
import userActions from '../../../actions/userActions';
import axios from "axios";
import { history } from '../../../helpers/history';
import config from '../../../config';
import Logo from '../../../assets/images/logoTop.svg' // relative path to image
import '../../../assets/css/sections/login.css';


class Login extends React.Component {

  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      loginLoading: false,
      username: '',
      password: '',
      message: '',
      userid: '',
      passwordId: Math.floor((Math.random() * 10000) + 1),
      usernameId: Math.floor((Math.random() * 10000) + 1),
      modal: false
    }


    this.particlesInit = async (main) => {
      console.log(main);

      // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadFull(main);
    };


    this._onChangeLanguage = this._onChangeLanguage.bind(this);
    this._onHandleScanSuccess = this._onHandleScanSuccess.bind(this);
    this._onHandleScanError = this._onHandleScanError.bind(this);
  }
  componentDidMount() {
    //console.log(this);
    var component = this;
    this.props.logout();
  }

  _onChangeLanguage = (lng) => {
    //console.log(lng);
    //console.log(i18n);
    localStorage.setItem('lang', lng);
    i18n.changeLanguage(lng);
  }

  _onSubmit = event => {
    event.preventDefault();

    var component = this;

    component.setState({
      loginLoading: true
    });

    const { username, password } = this.state;
    if (username && password) {
      this.props.login(username, password).then(function(e) {
        console.log(e);
        component.setState({
          loginLoading: false
        }, function() {
          if(e.type == 'USERS_LOGIN_SUCCESS') {
            history.push('/dashboard');
          }
        });
      });
    }
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onHandleScanError = error => {
    var component = this;
    console.error(error);
    if(error.name == "NotFoundError" || error.name == "NotAllowedError" || error.name == "OverconstrainedError") {
    }
  }

  _onHandleScanSuccess = data => {
    var component = this;



    //console.log(data);
    if(data != null && !component.state.loginLoading) {
      //alert(data);

      component.setState({
        loginLoading: true
      }, function() {


        component.props.loginByCard(data).then(function(e) {
          component.setState({
            loginLoading: false
          }, function() {
            if(e.type == 'USERS_LOGIN_SUCCESS') {
              history.push('/dashboard');
            }
          });
        });

      });



    }
  }

  render() {



    const errorState  = this.props.authenticationReducer.error;
    return (
      <section id="login-bar">

        <Particles id="particles-js"
          init={this.particlesInit}
          options={{
              particles: {
                  number: {
                      value: 30,
                      density: {
                          enable: true,
                          value_area: 1000
                      }
                  },
                  color: {
                      value: '#fff'
                  },
                  opacity: {
                      value: 0.5,
                      anim: {
                          enable: true
                      }
                  },
                  size: {
                      value: 65,
                      random: true,
                      anim: {
                          enable: true,
                          speed: 4
                      }
                  },
                  line_linked: {
                      enable: false
                  },
                  move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 1.4,
                        straight: false,
                    },
               }
          }}
        />




        <div className='classic-form-page' id='login'>
          <MDBView>
            <MDBMask
              className='d-flex justify-content-center align-items-center'
              overlay='stylish-strong'
            >
              <MDBContainer>
                <MDBRow>
                  <MDBCol md='5' lg='5' xl='5' sm='12' className='mx-auto'>
                    <MDBCard>
                      <MDBCardBody>

                        <div className="logo">
                            <img src={Logo} className="img-fluid logo" />
                        </div>


                          <form
                            className="needs-validation"
                            onSubmit={this._onSubmit}
                            autoComplete="none"
                            name="login-form"
                          >


                          <input type="hidden" value="prayer" />
                          <input id="username" className="hide" type="text" name="username" />
                          <input id="password" className="hide" type="password" name="password" />


                            <MDBInput
                              outline
                              id={this.state.usernameId}
                              value={this.state.username}
                              type='text'
                              label={this.props.t('Benutzername')}
                              name='username'
                              onChange={e => this.setState({ "username" : e.target.value })}
                              required
                              autoComplete="off"
                            />
                            <MDBInput
                              outline
                              id={this.state.passwordId}
                              value={this.state.password}
                              type='password'
                              label={this.props.t('Passwort')}
                              name='password'
                              onChange={e => this.setState({ "password": e.target.value })}
                              required
                              autoComplete="new-password"
                            />

                          {this.state.loginLoading &&
                              <div className="text-center"><MDBSpinner red /></div>
                            }

                            {!this.state.loginLoading &&
                              <div className=''>
                                <MDBBtn type="submit">
                                  <MDBIcon icon="sign-in-alt mr-2" /> {this.props.t('Login')}
                                </MDBBtn>
                                <MDBBtn onClick={this._onModalToggle}><MDBIcon icon="qrcode" /> Kartenlogin</MDBBtn>
                              </div>
                            }


                            <MDBContainer>

                              <MDBModal size="lg" isOpen={this.state.modal} toggle={this._onModalToggle}>
                                <MDBModalHeader  toggle={this._onModalToggle}>Kartenlogin</MDBModalHeader>
                                <MDBModalBody>


                                    <div class="row">
                                      <div className="col-md-6">
                                        <div className="d-flex justify-content-center">
                                          <QrReader
                                            className="scanner"
                                            delay={500}
                                            onError={this._onHandleScanError}
                                            onScan={this._onHandleScanSuccess}
                                            style={{ width: '100%' }}
                                            facingMode={'front'}
                                            showViewFinder={true}
                                            resolution={1024}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <img src={EmployeeCardImage} className="img-fluid" style={{padding:'35px'}} />
                                      </div>
                                    </div>




                                </MDBModalBody>
                              </MDBModal>
                            </MDBContainer>

                          </form>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </MDBMask>
          </MDBView>
        </div>



      </section>
    );
  }
}

function mapState(state) {
  //console.log(state);
  const { authenticationReducer } = state;
  return { authenticationReducer };
}

const actionCreators = {
    login: userActions.login,
    loginByCard: userActions.loginByCard,
    logout: userActions.logout
};

const connectedLoginPage = withTranslation('translation', { withRef: true })(connect(mapState, actionCreators)(Login));
export { connectedLoginPage as Login };
