import React, { Component } from 'react';
import { MDBCard, MDBCardBody, MDBIcon, MDBRow, MDBCol, MDBCardText, MDBBtn, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBSpinner } from 'mdbreact';

class DailyStats extends Component {
    state = {
        modal1: false,
        modal2: false,
        modal3: false,
        modal4: false,
        modal5: false,
        modal6: false,
        modal7: false,
        modal8: false,
        modal9: false,
        modal10: false,
        modal11: false,
        modal12: false,
        modal13: false
    }

    toggle = nr =>  () => {
        let modalName = 'modal' + nr;
        this.setState({
            [modalName]: !this.state[modalName]
        })
    }


    render(){
  return (
    <MDBRow className="mb-4 daily-stats">
        <MDBCol xl="3" md="6" className="mb-r">

          <MDBCard color="primary-color" className="classic-admin-card">
            <MDBCardBody>
              <div className="float-right">
              <MDBIcon far icon="user"/>
              </div>
              <p className="white-text">Kunden</p>



                {!this.props.dashboard.state.loaded &&
                  <MDBSpinner red />
                }

                {this.props.dashboard.state.loaded &&
                  <h4><strong>{this.props.dashboard.state.result.customers}</strong></h4>
                }


                <div class="call-to-action hide">
                  <MDBBtn onClick={this.toggle(1)}>Details</MDBBtn>
                  <MDBModal isOpen={this.state.modal1} toggle={this.toggle(1)}>
                    <MDBModalHeader toggle={this.toggle(1)}>MDBModal title</MDBModalHeader>
                    <MDBModalBody>
                      (...)
                    </MDBModalBody>
                  </MDBModal>
                </div>
            </MDBCardBody>


          </MDBCard>


        </MDBCol>
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard color="primary-color" className="classic-admin-card">

              <MDBCardBody>
                <div className="float-right">
                <MDBIcon icon="user-nurse"/>
                </div>
                <p className="white-text">Behandlungen</p>

                  {!this.props.dashboard.state.loaded &&
                    <MDBSpinner red />
                  }

                  {this.props.dashboard.state.loaded &&
                    <h4><strong>{this.props.dashboard.state.result.treatments}</strong></h4>
                  }



                <div class="call-to-action hide">
                  <MDBBtn onClick={this.toggle(1)}>Details</MDBBtn>
                  <MDBModal isOpen={this.state.modal1} toggle={this.toggle(1)}>
                    <MDBModalHeader toggle={this.toggle(1)}>MDBModal title</MDBModalHeader>
                    <MDBModalBody>
                      (...)
                    </MDBModalBody>
                  </MDBModal>
                </div>
              </MDBCardBody>
            </MDBCard>
        </MDBCol>
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard color="primary-color" className="classic-admin-card">

              <MDBCardBody>
                <div className="float-right">
                <MDBIcon far icon="money-bill-alt"/>
                </div>
                <p className="white-text">Umsatz</p>
                  {!this.props.dashboard.state.loaded &&
                    <MDBSpinner red />
                  }

                  {this.props.dashboard.state.loaded &&
                    <h4><strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.dashboard.state.result.sales)}</strong></h4>
                  }


                <div class="call-to-action hide">
                  <MDBBtn onClick={this.toggle(1)}>Details</MDBBtn>
                  <MDBModal isOpen={this.state.modal1} toggle={this.toggle(1)}>
                    <MDBModalHeader toggle={this.toggle(1)}>MDBModal title</MDBModalHeader>
                    <MDBModalBody>
                      (...)
                    </MDBModalBody>
                  </MDBModal>
                </div>
              </MDBCardBody>
            </MDBCard>
        </MDBCol>
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard color="primary-color" className="classic-admin-card">

              <MDBCardBody>
                <div className="float-right">
                <MDBIcon far icon="calendar-alt"/>
                </div>
                <p className="white-text">Termine</p>
                  {!this.props.dashboard.state.loaded &&
                    <MDBSpinner red />
                  }

                  {this.props.dashboard.state.loaded &&
                    <h4><strong>{this.props.dashboard.state.result.appointments}</strong></h4>
                  }


                <div class="call-to-action hide">
                  <MDBBtn onClick={this.toggle(1)}>Details</MDBBtn>
                  <MDBModal isOpen={this.state.modal1} toggle={this.toggle(1)}>
                    <MDBModalHeader toggle={this.toggle(1)}>MDBModal title</MDBModalHeader>
                    <MDBModalBody>
                      (...)
                    </MDBModalBody>
                  </MDBModal>
                </div>
              </MDBCardBody>
            </MDBCard>
        </MDBCol>
      </MDBRow>
  )
}
}

export default DailyStats;
