import React, { Component } from 'react';
import Pager from 'react-pager';
import CategoryResultRow from "./CategoryResultRow";
import CategoryActions from "./actions/CategoryActions";
import Image from "../image/Image";
import CategoryFilter from "./CategoryFilter";
import CategoryForm from "./CategoryForm";
import PermissionHelper from "../../../helpers/PermissionHelper";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';


class CategorySearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_searchterm: null,
        field_category: null
      }
    }


    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadCategoryCollection();
    });
  }

  componentDidMount(){
    this.loadCategoryCollection();
    //console.log(this);
  }

  loadCategoryCollection() {


      var component = this;

      /** build filter query **/

      CategoryActions._get('All', this.state.filters, this.state.page).then(response => {

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        component.setState({
          result: result,
          loaded: true,
          total: response.data.total
        });

      });
  }

  render() {
    let component = this;
    return (
      <>

      {PermissionHelper._hasPermission('restful post Category') === true &&
        <>
          <CategoryForm searchComponent={this} ref={this.formRef} nid={this.props.nid} />
        </>
      }

      <hr />

        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'category-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

          {/*<CategoryFilter searchComponent={this} /><hr />*/}

            { component.state.result.length > 0 && (


              <MDBTable responsive hover striped>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Kartennummer
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <CategoryResultRow result={row[1]}  key={'category-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">Keine Categorys vorhanden</div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default CategorySearch;
