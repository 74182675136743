import React, { Component } from 'react';
import {
  MDBCard,
  MDBSpinner,
  MDBLink,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBAnimation,
  MDBBadge,
  MDBModal,
  MDBModalBody,
  MDBModalFooter
} from 'mdbreact';
import PermissionHelper from "../../../helpers/PermissionHelper";
import { history } from '../../../helpers/history';

import NumberFormat from 'react-number-format';

import CashRegisterSessionActions from "./actions/CashRegisterSessionActions";

import CashMovementSearch from '../cashmovement/CashMovementSearch';


import OrderSearch from "../order/OrderSearch";

import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';


import moment from 'moment';
import 'moment/locale/de';

/** import styles **/
import '../../../assets/css/sections/cashregistersession.css';





class CashRegisterSessionDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      entity: null,
      nid: this.props.match.params.id,
      activeItem: "1",
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onClose = this._onClose.bind(this);
    this._onToggleTab = this._onToggleTab.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  componentDidMount() {
    CashRegisterSessionActions._get(this.props.match.params.id).then(response => {
      //console.log(response);
      this.setState({
        entity: response.data.entity,
        loaded: true
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {

  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete() {
    const r = window.confirm("Löschen Bestätigen");
    if(r === true){
      this.setState({
        loaded: false
      });
      CashRegisterSessionActions._delete(this.props.match.params.id).then(response => {
        history.push('/customer');
      });
    }
  }

  _onClose() {
    const r = window.confirm("Schliesen Bestätigen");
    if(r === true){
      this.setState({
        loaded: false
      });

      var values = {
        data: {
          field_crs_closed: 1
        }
      }

      values.nid = this.props.match.params.id;
      CashRegisterSessionActions._patch(values)
        .then(response => {
          history.push('/dashboard');
        });


    }
  }


  _onToggleTab = tab => () => {
    if (this.state.activeItem !== tab) {
    this.setState({
      activeItem: tab
    });
    }
  }

  render() {

    let component = this;

    return (
      <section id="cashregistersession-detail">
        <MDBCard>
          <MDBCardBody tag="div">
            <MDBCardTitle>

              <MDBRow>
                <MDBCol size="6" className="">
                    Kassensitzung: #
                    {this.state.entity &&
                      <span>{this.state.entity.node.field_cash_register_session_numb[0].value}</span>
                    }
                </MDBCol>
                <MDBCol size="6">

                  {this.state.entity &&
                  <div className="action-bar">
                    <MDBNavLink to={"/cashregister/" + this.state.entity.node.field_cash_register[0].target_id} className="mr-2">
                      <MDBBtn size="sm" color="info">
                        <MDBIcon icon="chevron-left" />
                      </MDBBtn>
                    </MDBNavLink>
                  </div>
                  }

                </MDBCol>
              </MDBRow>



            </MDBCardTitle>



            {!this.state.loaded &&
              <div className="text-center">
                <MDBSpinner red />
              </div>
            }


            {this.state.loaded &&
              <MDBCardText tag="div">
                <div className="row">

                  <div className="col-md-6">
                    <h3>Details</h3>

                      <div className="item">
                        <div className="label">eröffent am</div>
                        <div className="value">
                          {moment(this.state.entity.node.created[0].value).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")}
                        </div>
                      </div>


                      <div className="item">
                        <div className="label">geschlossen am</div>
                        <div className="value">
                          {moment.unix(this.state.entity.cashRegisterCloseDate).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")}
                        </div>
                      </div>

                      <div className="item">
                        <div className="label">Kassestock</div>
                        <div className="value">
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.entity.node.field_cash_amount[0].value)}
                        </div>
                      </div>

                      <div className="item">
                        <div className="label">eröffnet von</div>
                        <div className="value">
                          {this.state.entity.user.field_firstname[0].value} {this.state.entity.user.field_lastname[0].value}
                        </div>
                      </div>


                      <div className="item">
                        <div className="label">geschlossen von</div>
                        <div className="value">
                          {this.state.entity.cashRegisterCloser && this.state.entity.cashRegisterCloser.field_firstname[0] && this.state.entity.cashRegisterCloser.field_lastname[0] &&
                            <> {this.state.entity.cashRegisterCloser.field_firstname[0].value} {this.state.entity.cashRegisterCloser.field_lastname[0].value}</>
                          }

                        </div>
                      </div>



                  </div>
                  <div className="col-md-6">
                    <h3>Aktionen</h3>




                                  <MDBBtn color="primary" onClick={this._onModalToggle}>
                                      <MDBIcon far icon="file-pdf" /> Tagesabschluss
                                  </MDBBtn>



                                  <MDBModal className="text-left" isOpen={this.state.modal} toggle={this._onModalToggle} size="lg">
                                          <MDBModalBody>
                                            {this.state.entity.file &&
                                              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
                                                  <div style={{ height: '750px' }}>
                                                      <Viewer
                                                          fileUrl={this.state.entity.file}
                                                      />
                                                  </div>
                                              </Worker>

                                            }
                                            <hr />
                                            {this.state.entity.node.body[0] &&
                                              <span dangerouslySetInnerHTML={{__html: this.state.entity.node.body[0].value}} />
                                            }

                                          </MDBModalBody>
                                          <MDBModalFooter>
                                            <MDBBtn color="danger" onClick={this._onModalToggle}><MDBIcon icon="times" /> schliesen</MDBBtn>
                                          </MDBModalFooter>
                                        </MDBModal>


                  </div>
                </div>
                <hr />
                  <div className="row">
                    <div className="col-md-12">

                      <MDBNav tabs>
                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            active={this.state.activeItem === "1"}
                            onClick={this._onToggleTab("1")}
                            role="tab"
                          >
                            Bestellungen
                          </MDBNavLink>
                        </MDBNavItem>

                        <MDBNavItem>
                          <MDBNavLink
                            link
                            to="#"
                            active={this.state.activeItem === "2"}
                            onClick={this._onToggleTab("2")}
                            role="tab"
                          >
                            Betriebsausgaben
                          </MDBNavLink>
                        </MDBNavItem>

                      </MDBNav>
                      <MDBTabContent
                        className="card"
                        activeItem={this.state.activeItem}
                      >
                      <MDBTabPane tabId="1" role="tabpanel">
                        <OrderSearch cashRegisterSessionId={this.state.entity.node.nid[0].value} />
                      </MDBTabPane>

                      <MDBTabPane tabId="2" role="tabpanel">
                        <CashMovementSearch cashRegisterSessionId={this.state.entity.node.nid[0].value} />
                      </MDBTabPane>

                      </MDBTabContent>



                    </div>
                  </div>
              </MDBCardText>
            }



          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

//export default Customers;
export default CashRegisterSessionDetail;
