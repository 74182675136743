import React, {
  useState,
  useEffect
} from 'react';

import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBAvatar,
  MDBModalHeader,
  MDBProgress
} from 'mdbreact';

import Image from "../image/Image";

import NumberFormat from 'react-number-format';


import NewsletterActions from "./actions/NewsletterActions";
import NewsletterForm from "./NewsletterForm";
import NewsletterSend from "./NewsletterSend";

import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../helpers/PermissionHelper";


import { Modal, Button } from 'antd';
import { Tooltip, Progress } from 'antd';



class NewsletterResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
  }



  _onDelete(e) {
    var component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      NewsletterActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadNewsletterCollection();
        });
    }
  }


  _onToggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }


  showModal = () => {
    this.setState({
      modal: true
    });
   };

   handleOk = () => {
     this.setState({
       modal: false
     });
     this.props.searchComponent.loadNewsletterCollection();
   };

   handleCancel = () => {
     this.setState({
       modal: false
     });
     this.props.searchComponent.loadNewsletterCollection();
   };

  render() {
    return (
      <tr className="newsletter-item" key={'newsletter-' + this.props.result.nid + this.props.result.changed}>

        <td>{(this.props.result.node.field_newsletter_number[0] ? this.props.result.node.field_newsletter_number[0].value : '')}</td>
        <td>{(this.props.result.node.title[0] ? this.props.result.node.title[0].value : '')}</td>
        <td>{this.props.result.node.field_newsletter_log.length} / {this.props.searchComponent.state.numbers.length}</td>
        <td>
          <div className="action-bar">
            {!this.props.result.node.field_newsletter_sent[0].value &&
              <>

              <MDBBtn color="primary" onClick={this.showModal}><MDBIcon icon="paper-plane" /></MDBBtn>

              <Modal className="modal-footer-hidden" title={this.props.result.node.title[0].value} visible={this.state.modal} onOk={this.handleOk} onCancel={this.handleCancel}>


                  <article id="newsletter-preview">
                    <p class="from-me last">{this.props.result.node.body[0].value}</p>
                  </article>

                  <hr />

                  <NewsletterSend node={this.props.result.node} numbers={this.props.searchComponent.state.numbers} />

              </Modal>
              </>
            }

            {this.props.result.node.field_newsletter_sent[0].value &&
              <MDBBtn color="primary"><MDBIcon icon="check" /></MDBBtn>
            }

            {PermissionHelper._hasPermission('restful delete Newsletter') === true &&
              <>
                <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
              </>
            }




          </div>


        </td>
      </tr>
    );
  }
}

export default NewsletterResultRow;
