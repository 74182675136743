import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';

import CategorySearch from "./CategorySearch";

/** import config **/
import config from '../../../config';

/** import styles **/
import '../../../assets/css/sections/category.css';


class CategoryOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }

    this.sectionRef = React.createRef();
  }

  render() {
    return (
      <section id="category-overview" ref={this.sectionRef}>
        <MDBCard className="card-category">
          <MDBCardBody>
            <MDBCardTitle><MDBIcon icon="categorypaper mr-2" /> Kategorien</MDBCardTitle>
            <MDBCardText tag="div">
              <CategorySearch overviewComponent={this} />
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

export default CategoryOverview;
