import React, {
  useState,
  useEffect
} from 'react';



import { Modal } from 'antd';

import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBAvatar,
  MDBModalHeader,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBContainer
} from 'mdbreact';

import Image from "../image/Image";

import NumberFormat from 'react-number-format';


import EmployeeActions from "./actions/EmployeeActions";
import EmployeeForm from "./EmployeeForm";
import EmployeeTreatmentAdjust from "./EmployeeTreatmentAdjust";
import EmployeeHistory from "./EmployeeHistory";
import UserCardSearch from "../usercard/UserCardSearch";
import EmployeeComment from "../comment/CommentSearch";

import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../helpers/PermissionHelper";



class EmployeeResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      activeItem: "1"
    };

    this._onDelete = this._onDelete.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onTabToggle = tab => () => {
    if (this.state.activeItem !== tab) {
    this.setState({
      activeItem: tab
    });
    }
  }

  _onDelete(e) {
    var component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      EmployeeActions._delete(this.props.result.uid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadEmployeeCollection();
        });
    }
  }

  render() {
    //console.log(this.props);
    return (
      <tr className="employee-item" key={'employee-' + this.props.result.uid + this.props.result.changed}>

        <td>{(this.props.result.user.field_employee_number[0] ? this.props.result.user.field_employee_number[0].value : '')}</td>
        <td>{(this.props.result.user.field_firstname[0] ? this.props.result.user.field_firstname[0].value : '')}</td>
        <td>{(this.props.result.user.field_lastname[0] ? this.props.result.user.field_lastname[0].value : '')}</td>
        <td>{(this.props.result.mail ? this.props.result.mail : '')}</td>
        <td>{(this.props.result.user.field_mobile[0] ? <NumberFormat displayType={'text'} format="### ### ## ##" mask="_" value={this.props.result.user.field_mobile[0].value}/> : '')}</td>

        <td>

          <div className="action-bar">
            <MDBBtn color="info" onClick={this._onModalToggle}><MDBIcon icon="info-circle" /></MDBBtn>
            {PermissionHelper._hasPermission('administer users') === true &&
              <>
                <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
                <EmployeeForm key={'employee-edit-form-' + this.props.result.uid + this.props.result.changed} entity={this.props.result.user} locations={this.props.result.locations} roles={this.props.result.roles} treatmentTypes={this.props.result.treatmentTypes} searchComponent={this.props.searchComponent} />
              </>
            }
          </div>


          <Modal destroyOnClose={true} width={800} title="Details" visible={this.state.modal} onOk={this._onModalToggle} onCancel={this._onModalToggle} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
            <MDBRow className="employee-item">
              <MDBCol className="mb-lg-0 mb-5">


                {/*
                {this.props.result.user.field_image[0] &&
                  <div className="avatar mx-auto rounded-circle z-depth-1 img-fluid">
                    <Image fid={this.props.result.user.field_image[0].target_id.toString()} imagestyle="employee_detail" className="rounded-circle z-depth-1 img-fluid" />
                  </div>
                }*/}


                  <div className="text-bar">
                    <strong>Rollen:</strong>
                    <ul className="role-list">
                      {
                        this.props.result.roles.map(function(item, i){
                          //console.log(item);
                          return <li key={'role-' + i}><span>{item}</span></li>
                        })
                      }
                    </ul>
                  </div>


                  <div className="text-bar">
                    <strong>Standorte:</strong>
                    <ul className="location-list">
                      {
                        this.props.result.locations.map(function(item, i){
                          //console.log(item);
                          return <li key={'location-' + i}><span>{item.title[0].value}</span></li>
                        })
                      }
                    </ul>
                  </div>

                  <div className="text-bar">
                    <span className="phone"><strong>Geburtstag:</strong> {(this.props.result.user.field_birthday[0]) ? moment(this.props.result.user.field_birthday[0].value).format('DD.MM.YYYY') : ""}</span>
                  </div>
                  <div className="text-bar">
                    <span className="phone">
                      <strong>Telefon: </strong>



                      {(this.props.result.user.field_phone[0] ? <NumberFormat displayType={'text'} format="### ### ## ##" mask="_" value={this.props.result.user.field_phone[0].value}/> : '')}
                    </span>
                  </div>
                  <div className="text-bar">
                    <span className="phone">
                      <strong>Mobil: </strong>
                      {(this.props.result.user.field_mobile[0] ? <NumberFormat displayType={'text'} format="### ### ## ##" mask="_" value={this.props.result.user.field_mobile[0].value}/> : '')}
                    </span>
                  </div>
                  <div className="text-bar">
                    <span className="email"><strong>Email:</strong> {this.props.result.mail}</span>
                  </div>
                  <div className="text-bar">
                    <strong>Adresse:</strong><br />
                    <span className="street">{this.props.result.user.field_street[0].value}</span><br />
                    <span className="street">{this.props.result.user.field_zip[0].value}</span> <span className="street">{this.props.result.user.field_city[0].value}</span>
                  </div>

                  <hr />



                  {PermissionHelper._hasPermission('administer users') === true &&
                    <>
                    <MDBNav tabs>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to="#"
                          active={this.state.activeItem === "1"}
                          onClick={this._onTabToggle("1")}
                          role="tab"
                        >
                          <MDBIcon icon="qrcode mr-2" /> Mitarbeiterkarte
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to="#"
                          active={this.state.activeItem === "2"}
                          onClick={this._onTabToggle("2")}
                          role="tab"
                        >
                          <MDBIcon icon="comment mr-2" /> Kommentare
                        </MDBNavLink>
                      </MDBNavItem>
                      <MDBNavItem>
                        <MDBNavLink
                          link
                          to="#"
                          active={this.state.activeItem === "3"}
                          onClick={this._onTabToggle("3")}
                          role="tab"
                        >
                          <MDBIcon icon="doctor mr-2" /> Behandlungszeiten
                        </MDBNavLink>
                      </MDBNavItem>
                    </MDBNav>
                    <MDBTabContent
                      activeItem={this.state.activeItem}
                    >
                    <MDBTabPane tabId="1" role="tabpanel" className="text-left">
                      <UserCardSearch uid={this.props.result.user.uid[0].value} />
                    </MDBTabPane>
                    <MDBTabPane tabId="2" role="tabpanel" className="text-left">
                      <EmployeeComment showImportant={true} entityId={this.props.result.user.uid[0].value} entityType="user" fieldName="field_employee_comment" commentType="employee_comment" />
                    </MDBTabPane>
                    <MDBTabPane tabId="3" role="tabpanel" className="text-left">
                      <EmployeeTreatmentAdjust employee={this.props.result.user} />
                    </MDBTabPane>
                  </MDBTabContent>
                  </>
                }
              </MDBCol>
            </MDBRow>
          </Modal>


        </td>
      </tr>
    );
  }
}

export default EmployeeResultRow;
