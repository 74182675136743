import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBStepper,
  MDBStep,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner
} from 'mdbreact';

import EvaluationActions from "./actions/EvaluationActions";



/** import config **/
import config from '../../../config';




class EvaluationViewStep extends Component {

  constructor(props) {
    super(props);


    this.state = {
      file: null,
      modal: false,
      loaded: true,
    }

    this._onSubmitData = this._onSubmitData.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }


  _onSubmitData() {

    var component = this;

    component.props.stepper.setState(prevState => ({
      result: [],
      loaded: false
    }))

    component.setState(prevState => ({
      loaded: false
    }))




    var filters = component.props.stepper.state.filters;



    EvaluationActions._get('get', filters, 0).then(response => {

        component.setState(prevState => ({
          file: response.data.file,
          loaded: true
        }))


        const linkSource = response.data.file;
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);

        downloadLink.href = linkSource;
        downloadLink.target = '_self';
        downloadLink.download = 'bericht-'+filters.field_report_type+'-'+filters.field_from+'-'+filters.field_to+'.xlsx';
        downloadLink.click();

      });
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  render() {
    return (
      <section id="evaluation-date">
        {this.state.loaded &&
          <MDBBtn color="primary" onClick={this._onSubmitData}>
            <MDBIcon icon="cloud-download-alt" /> Auswertung herunterladen
          </MDBBtn>
        }


        {!this.state.loaded &&
          <MDBSpinner small red />
        }

      </section>
    );
  }
}

export default EvaluationViewStep;
