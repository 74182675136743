import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';
import PermissionHelper from "../../../helpers/PermissionHelper";

import Select from 'react-select'

import LimitSelect from "../misc/LimitSelect";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';

class OrderFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      orderFilter: null,
      orderStatusFilter: null
    }

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onDirectionChange = this._onDirectionChange.bind(this);
    this._updateSearchFilter = this._updateSearchFilter.bind(this);
  }

  componentDidMount() {
    var component = this;
    var orderStatusArray = this.props.searchComponent.state.orderStatusArray;
    var orderStatusFilter = new Array();
    if(orderStatusArray) {
      Object.entries(orderStatusArray).forEach(function(item, key){
        var optionItem = {
          label: item[1].name,
          value: item[1].id,
          field_closed: item[1].field_closed,
          field_completed: item[1].field_completed,
          field_open: item[1].field_open,
          field_refunded: item[1].field_refunded,
        }
        orderStatusFilter.push(optionItem);
        console.log(orderStatusFilter);
      });
    }

    this.setState(prevState => ({
      orderStatusFilter: orderStatusFilter,
    }))

  }

  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onDirectionChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this._updateSearchFilter(filters);
  }

  _onInputChange(e) {
    //console.log(e);
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _updateSearchFilter(filters) {

    var component = this;

    //console.log(filters);
    component.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        var orderFilter = component.props.searchComponent.state;
        localStorage.setItem('orderFilter', JSON.stringify(orderFilter));
        component.props.searchComponent.loadOrderCollection();
    });
  }

  render() {
    let component = this;

    if(this.state.orderStatusFilter && this.state.orderStatusFilter.length > 0) {

      /*
      console.log(this.state.orderStatusFilter);
      var defaultStatusFilter = {
        label: this.state.orderStatusFilter.find(x => x.field_open === '1').label,
        value: this.state.orderStatusFilter.find(x => x.field_open === '1').value
      };*/

      var defaultStatusFilter = false;

      /*
      defaultStatusFilter = {
        label: this.state.orderStatusFilter.find(x => x.field_open === '1').label,
        value: this.state.orderStatusFilter.find(x => x.field_open === '1').value
      }*/

      if(this.props.searchComponent.state.filters.field_order_status) {
        defaultStatusFilter = {
          label: this.state.orderStatusFilter.find(x => x.value === this.props.searchComponent.state.filters.field_order_status).label,
          value: this.state.orderStatusFilter.find(x => x.value === this.props.searchComponent.state.filters.field_order_status).value
        };
      }


    }


    return (
      <div className="filter-card">
        <MDBRow>
          <MDBCol md="3" xs="12">

            {this.state.orderStatusFilter && this.state.orderStatusFilter.length > 0 &&
              <Select
                isSearchable={false}
                className="react-select"
                classNamePrefix="react-select"
                placeholder="Status"
                options={this.state.orderStatusFilter}
                defaultValue={defaultStatusFilter}
                isClearable={true}
                onChange={(value) => {

                  if(value) {
                    value = value.value;
                  } else {
                    value = '';
                  }

                  this._onSelectChange({
                    target: {
                      name: 'field_order_status',
                      value: value,
                    },
                  });
                }}
              />
            }
          </MDBCol>
          <MDBCol md="3" xs="12" className="d-flex col-sort">

            <Select
              isSearchable={false}
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Sortierung"
              options={this.props.searchComponent.state.sortOptions}
              isClearable={true}
              onChange={(value) => {

                if(value) {
                  value = value.value;
                } else {
                  value = '';
                }

                this._onSelectChange({
                  target: {
                    name: 'field_sort',
                    value: value,
                  },
                });
              }}
            />

          <LimitSelect onSelectChange={this._onSelectChange} />

          <MDBBtn flat size="lg" color="primary" onClick={(e) => {
              this._onDirectionChange({
                target: {
                  name: 'field_sort_direction',
                  value: (this.props.searchComponent.state.filters.field_sort_direction == 'asc') ? "desc" : "asc",
                },
              });
          }}>
          {this.props.searchComponent.state.filters.field_sort_direction == 'asc' &&
            <MDBIcon icon="sort-alpha-down-alt" />
          }

          {this.props.searchComponent.state.filters.field_sort_direction == 'desc' &&
            <MDBIcon icon="sort-alpha-up-alt" />
          }

          </MDBBtn>

          </MDBCol>
        </MDBRow>
        <hr />
          <MDBRow>
            <MDBCol>
                <div className="sui-search-box">
                  <div className="sui-search-box__wrapper">
                    <DebounceInput
                      minLength={3}
                      debounceTimeout={800}
                      id="field_searchterm"
                      name="field_searchterm"
                      placeholder="Name"
                      className="sui-search-box__text-input "
                      onChange={(e) => {
                        this._onInputChange({
                          target: {
                            name: 'field_searchterm',
                            value: e.target.value,
                          },
                        });
                      }}
                      value={this.props.searchComponent.state.filters.field_searchterm}
                    />

                   {!this.props.searchComponent.state.collectionLoaded && (
                     <><MDBSpinner red small /></>
                   )}
                  </div>
                </div>
            </MDBCol>
          </MDBRow>
      </div>
    )
  }

}

export default OrderFilter;
