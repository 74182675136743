import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBStepper,
  MDBStep,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBContainer
} from 'mdbreact';

import Select from 'react-select'



/** import config **/
import config from '../../../config';


class EvaluationTypeStep extends Component {

  constructor(props) {
    super(props);

    this._onChangeSelect = this._onChangeSelect.bind(this);
  }

  _onChangeSelect(e) {
    var component = this;


    var filters = this.props.stepper.state.filters;
    filters.field_report_type = e.value;

    this.props.stepper._updateSearchFilter(filters);
  }


  render() {

    const options = [
      { value: 'day', label: 'Tagesbericht' },
      { value: 'month', label: 'Monatsbericht' },
      { value: 'year', label: 'Jahresbericht' },
      { value: 'employee', label: 'Mitarbeiterbericht' },
      { value: 'product_category', label: 'Artikelkategoriebericht' },
      { value: 'product', label: 'Artikelbericht' },
      { value: 'payment', label: 'Zahlungsartbericht' },
      { value: 'cashmovement', label: 'Bergeldbewegungen' },
      { value: 'discount', label: 'Rabattbericht' },
      { value: 'order', label: 'Bestellbericht' },
      { value: 'cancellation', label: 'Stornobericht' },
      { value: 'refund', label: 'Erstattungsbericht' },
      { value: 'giftcards', label: 'Gutscheinbericht' },
      { value: 'subscription', label: 'Abobericht' },
      { value: 'gwreport', label: 'GW-Bericht' },
      { value: 'appointment-cancellation', label: 'Termin Stornierungen' },
      { value: 'appointment-notshowedup', label: 'Termin Nicht erschienen' },
      { value: 'appointment-nofollowing', label: 'Keine Folgetermine' },
    ]


    return (
      <section id="evaluation-date">
        <Select
          classNamePrefix={'custom-select'}
          defaultValue = {
              options.filter(option =>
                 option.value === this.props.stepper.state.filters.field_report_type)
          }
          onChange={this._onChangeSelect}
          options={options}
        />


      </section>
    );
  }
}

export default EvaluationTypeStep;
