import React, { Component } from 'react';
import Pager from 'react-pager';
import TreatmentDurationAdjustResultRow from "./TreatmentDurationAdjustResultRow";
import TreatmentDurationAdjustActions from "./actions/TreatmentDurationAdjustActions";
import Image from "../image/Image";
import TreatmentDurationAdjustFilter from "./TreatmentDurationAdjustFilter";
import TreatmentDurationAdjustForm from "./TreatmentDurationAdjustForm";
import PermissionHelper from "../../../helpers/PermissionHelper";
import { Empty } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';


class TreatmentDurationAdjustSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      products: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_searchterm: null,
        field_employee: this.props.employeeId,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc',
      }
    }


    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadTreatmentDurationAdjustCollection();
    });
  }

  componentDidMount(){
    this.loadTreatmentDurationAdjustCollection();
    //console.log(this);
  }

  loadTreatmentDurationAdjustCollection() {


      var component = this;

      /** build filter query **/

      TreatmentDurationAdjustActions._get('All', this.state.filters, this.state.page).then(response => {

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }


        let products = response.data.products;

        let productsCascaderFilter = new Array();
        if(products) {
          //console.log(products);
          Object.entries(products).forEach(function(item, key){

            let product = item[1].node;
            let productCategory = item[1].productCategory;

            let objectItem;

            if(productsCascaderFilter.length > 0) {
              //console.log(productsCascaderFilter);
              objectItem = productsCascaderFilter.filter(item => {
                return item.value === productCategory.tid[0].value
              })
            }

            //console.log(objectItem);


            let optionItem;


            if(objectItem && objectItem.length > 0) {
              let productItem = {
                value: product.nid[0].value,
                label: product.title[0].value + '( '+ parseInt(product.field_duration[0].value) +' Min. )',
                duration: parseInt(product.field_duration[0].value),
                //label: product.title[0].value + '( '+ component.convertToTime(product.field_duration[0].value) +' )',
              }
              //console.log(objectItem);
              objectItem[0].children.push(productItem);
            } else {
              optionItem =  {
                value: productCategory.tid[0].value,
                label: productCategory.name[0].value,
                children: [
                  {
                    value: product.nid[0].value,
                    //label: product.title[0].value + '( '+ component.convertToTime(product.field_duration[0].value) +' )',
                    label: product.title[0].value + '( '+ parseInt(product.field_duration[0].value) +' Min. )',
                    duration: parseInt(product.field_duration[0].value),
                  }
                ],
              };
            }


            //productsFilter.push(optionItem);
            //productsCascaderFilter.push(<Option key={product.nid[0].value} value={product.nid[0].value} label={product.title[0].value}>{productCategory.name[0].value} | {product.title[0].value} ({component.convertToTime(product.field_duration[0].value)})</Option>);
            ////console.log(item);
            if(optionItem) {
              productsCascaderFilter.push(optionItem);
            }

          });

          console.log(productsCascaderFilter);

          component.setState(prevState => ({
            productsCascaderFilter: productsCascaderFilter
          }))
        }

        component.setState({
          products: response.data.products,
          result: result,
          loaded: true,
          total: response.data.total
        });

      });
  }

  render() {
    let component = this;
    return (
      <>

      {PermissionHelper._hasPermission('restful post TreatmentDurationAdjust') === true &&
        <>
          <TreatmentDurationAdjustForm searchComponent={this} ref={this.formRef} employeeId={this.props.employeeId} />
        </>
      }

      <hr />

        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'treatmentdurationadjust-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

          {/*<TreatmentDurationAdjustFilter searchComponent={this} /><hr />*/}

            { component.state.result.length > 0 && (


              <MDBTable responsive hover striped>
                <thead>
                  <tr>
                    <th>Behandlung</th>
                    <th>Anpassung in Minuten</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <TreatmentDurationAdjustResultRow result={row[1]}  key={'treatmentdurationadjust-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default TreatmentDurationAdjustSearch;
