import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon,
  MDBInput,
  MDBCollapse
} from 'mdbreact';

import InputSpinner from 'react-bootstrap-input-spinner'


import Currency from 'react-currency-formatter';


import PermissionHelper from "../../../helpers/PermissionHelper";


import moment from 'moment';
import 'moment/locale/de';



class OrderItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      total: this.props.result.node.field_qty[0].value * this.props.result.node.field_price[0].value,
      field_price:  this.props.result.node.field_price[0].value,
      field_qty: this.props.result.node.field_qty[0].value,
      collapseID: ""
    };

  }




  render() {
    var component = this;
    console.log(this.props.result);

    return (

      <tr className={`${this.state.collapseID ? "active" : ""}`} key={'orderitem-'+this.props.result.node.nid[0].value+this.props.result.node.changed[0].value}>
          <td className="w-25">
            <span class="product-qty">{this.props.result.node.field_qty[0].value}</span>
          </td>

          <td className="w-50">
            <span class="product-name">{this.props.result.node.title[0].value}</span>
          </td>

          <td className="w-25">
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.state.total)}
          </td>
      </tr>
    );
  }
}

export default OrderItem;
