import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';


import OrderTransactionActions from "./actions/OrderTransactionActions";



import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class OrderTransactionResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onEdit = this._onEdit.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      OrderTransactionActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadOrderTransactionCollection();
        });
    }
  }

  _onEdit(e) {

  }

  render() {
    var component = this;
    console.log(this.props);

    return (
      <tr className="order-transaction-item">
        <td>{ this.props.result.node.field_order_transaction_number[0].value }</td>
        <td>
          {moment(this.props.result.node.created[0].value).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")}
        </td>
        <td>
          {moment(this.props.result.node.changed[0].value).tz('Europe/Zurich').format("DD.MM.YYYY HH:mm")}
        </td>
        <td>
          {this.props.result.paymentMethod.name[0].value}
        </td>
        <td>
          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.result.node.field_amount[0].value)}
        </td>
        <td>
          {this.props.result.node.field_tender_reference[0] &&
            <>{this.props.result.node.field_tender_reference[0].value}</>
          }
        </td>
        <td>
          {this.props.result.node.field_psp_reference[0] &&
            <>{this.props.result.node.field_psp_reference[0].value}</>
          }
        </td>
        <td className="text-right">



        </td>
      </tr>
    );
  }
}

export default OrderTransactionResultRow;
