import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';
import PermissionHelper from "../../../helpers/PermissionHelper";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';

class NewsletterFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      newsletterFilter: null
    }

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onDirectionChange = this._onDirectionChange.bind(this);
  }

  componentDidMount() {
    //console.log(this.props);
    /*
    var newsletterFilter = JSON.parse(localStorage.getItem('newsletterFilter'));
    if(!newsletterFilter) {
      var newsletterFilter = this.props.searchComponent.state;
      localStorage.setItem('newsletterFilter', JSON.stringify(newsletterFilter));
    }

    this.props.searchComponent.setState(newsletterFilter);*/
  }

  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var newsletterFilter = this.props.searchComponent.state;
        //localStorage.setItem('newsletterFilter', JSON.stringify(newsletterFilter));
        this.props.searchComponent.loadNewsletterCollection();
    });

  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var newsletterFilter = this.props.searchComponent.state;
        //localStorage.setItem('newsletterFilter', JSON.stringify(newsletterFilter));
        this.props.searchComponent.loadNewsletterCollection();
    });
  }

  _onDirectionChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var newsletterFilter = this.props.searchComponent.state;
        //localStorage.setItem('newsletterFilter', JSON.stringify(newsletterFilter));
        this.props.searchComponent.loadNewsletterCollection();
    });
  }

  _onInputChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        //var newsletterFilter = this.props.searchComponent.state;
        //localStorage.setItem('newsletterFilter', JSON.stringify(newsletterFilter));
        this.props.searchComponent.loadNewsletterCollection();
    });
  }

  render() {
    let component = this;
    return (
      <div className="filter-card">




          <MDBRow>
            <MDBCol>
                <div className="sui-search-box">
                  <div className="sui-search-box__wrapper">
                    <DebounceInput
                      minLength={3}
                      debounceTimeout={800}
                      id="field_searchterm"
                      name="field_searchterm"
                      placeholder="Titel"
                      className="sui-search-box__text-input "
                      onChange={(e) => {
                        this._onInputChange({
                          target: {
                            name: 'field_searchterm',
                            value: e.target.value,
                          },
                        });
                      }}
                      value={this.props.searchComponent.state.filters.field_searchterm}
                    />

                   {!this.props.searchComponent.state.collectionLoaded && (
                     <><MDBSpinner red small /></>
                   )}
                  </div>
                </div>
            </MDBCol>
          </MDBRow>
      </div>
    )
  }

}

export default NewsletterFilter;
