import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';


import TreatmentDurationAdjustActions from "./actions/TreatmentDurationAdjustActions";

import TreatmentDurationAdjustForm from "./TreatmentDurationAdjustForm";


import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class TreatmentDurationAdjustResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onEdit = this._onEdit.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      TreatmentDurationAdjustActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadTreatmentDurationAdjustCollection();
        });
    }
  }

  _onEdit(e) {

  }

  _onSelect(treatmentdurationadjust) {
    this.props.searchComponent.props.checkoutPayment._onHandleTreatmentDurationAdjustPayment(treatmentdurationadjust);
  }

  render() {

    console.log(this.props.result);

    let component = this;

    let defaultProduct = [];
    let selectedCategory = false;
    let selectedProduct = false;
    if(component.props.searchComponent.state.productsCascaderFilter) {
      defaultProduct = Object.entries(component.props.searchComponent.state.productsCascaderFilter).filter(category => {
        let child =  Object.entries(category[1].children).filter(childItem => {
          if(childItem[1].value == this.props.result.node.field_product[0].target_id) {
            selectedCategory = category;
            selectedProduct = childItem;
          }
        })
      })
    }

    console.log(selectedProduct);
    console.log(selectedCategory);


    return (
      <tr className="treatmentdurationadjust-item">
        <td>

          {selectedCategory[1].label} / {selectedProduct[1].label}

        </td>
        <td>{ this.props.result.node.field_duration[0].value } (min.)</td>
        <td className="text-right">
          <div className="action-bar">



              {PermissionHelper._hasPermission('restful delete TreatmentDurationAdjust') === true &&
                <>
                  <MDBBtn color="danger" className="btn-delete" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
                </>
              }
              {PermissionHelper._hasPermission('restful patch TreatmentDurationAdjust') === true &&
                <>
                  <TreatmentDurationAdjustForm key={'treatmentdurationadjust-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} searchComponent={this.props.searchComponent} />
                </>
              }
          </div>



        </td>
      </tr>
    );
  }
}

export default TreatmentDurationAdjustResultRow;
