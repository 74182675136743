import React, { Component } from 'react';
import Pager from 'react-pager';
import ProductResultRow from "./ProductResultRow";
import ProductActions from "./actions/ProductActions";
import Image from "../image/Image";
import ProductFilter from "./ProductFilter";
import ProductForm from "./ProductForm";
import PermissionHelper from "../../../helpers/PermissionHelper";
import { Empty } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';


class ProductSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      total: 0,
      visiblePage: 5,
      productCategoryData: [],
      filters: {
        field_searchterm: '',
        field_product_category: null,
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc',
        field_limit: 10,
        field_is_favorite: 0
      },
      sortOptions: [
        {
          label: 'Geändert',
          value: 'nfd.changed'
        },
        {
          label: 'Titel',
          value: 'nfd.title'
        }
      ]
    }


    var productCategoryData = null;
    var taxRulesData = null;

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadProductCollection();
    });
  }

  componentDidMount(){
    this.loadProductCollection();
    //console.log(this);
  }

  loadProductCollection() {


      var component = this;

      /** build filter query **/

      ProductActions._get('All', this.state.filters, this.state.page).then(response => {

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }



        var taxRulesData = response.data.taxRulesData;


        var productCategories = response.data.productCategoryData;
        var productCategoryData = new Array();
        if(productCategories) {
          Object.entries(productCategories).forEach(function(item, key){
            var optionItem = {
              label: item[1].name[0].value,
              value: item[1].tid[0].value,
            }
            productCategoryData.push(optionItem);
            //console.log(item);
          });
        }

        //console.log(productCategoriesFilter);

        if(this.state.productCategoryData.length == 0) {
          component.setState({
            productCategoryData: productCategoryData,
            productCategories: productCategories,
          });
        }


        component.setState({
          result: result,
          collectionLoaded: true,
          total: response.data.total,
          taxRulesData: taxRulesData,
          loaded: true,
        });



      });
  }

  render() {
    let component = this;




    return (
      <>

      {PermissionHelper._hasPermission('restful post Product') === true && this.state.loaded &&
        <>
          <ProductForm key={'product-add-form'} searchComponent={component} ref={this.formRef} />
          <hr />
        </>
      }

      {this.state.loaded &&
        <>
          <ProductFilter searchComponent={component} />
          <hr />
        </>
      }



        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'product-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

          {/*<ProductFilter searchComponent={this} /><hr />*/}

            { component.state.result.length > 0 && (


              <MDBTable responsive hover striped>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Titel
                    </th>
                    <th className='th-lg'>
                      Artikelnummer
                    </th>
                    <th className='th-lg'>
                      Preis
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <ProductResultRow result={row[1]}  key={'product-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.filters.field_limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default ProductSearch;
