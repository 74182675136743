import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';




/** import config **/
import config from '../../../config';

/** import styles **/
import '../../../assets/css/sections/statistic.css';


class StatisticOverview extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="statistic-overview" ref={this.sectionRef}>
        <MDBCard className="card-statistic">
          <MDBCardBody>
            <MDBCardTitle><MDBIcon icon="statisticpaper mr-2" /> Statistik</MDBCardTitle>
            <MDBCardText tag="div">




            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

export default StatisticOverview;
