import React, { Component } from 'react';
import PermissionHelper from "../../../helpers/PermissionHelper";
import ReactToPrint from 'react-to-print';


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from 'mdbreact';

import Countdown from 'react-countdown';

import Currency from 'react-currency-formatter';

import moment from 'moment';
import 'moment/locale/de';

import config from '../../../config';


class Totals extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subtotal: 0,
      total: 0,
      discount: 0,
      tax: 0,
    }
  }


  componentDidMount(){
    var component = this;
  }


  render() {
    let component = this;

    return (
      <section id="totals">

        <MDBCard>
          <MDBCardBody>

            <MDBRow>
              <MDBCol sm="8">
                <strong>Zwischensumme</strong>
              </MDBCol>
              <MDBCol sm="4">
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.subtotal)}
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol sm="8">
                <strong>Steuer</strong>
              </MDBCol>
              <MDBCol sm="4">
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.tax)}
              </MDBCol>
            </MDBRow>
            <hr />

            <MDBRow>
              <MDBCol sm="8">
                <strong>Gesamt</strong>
              </MDBCol>
              <MDBCol sm="4">
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.total)}

                {this.props.order.totals.total != this.props.order.totals.totalNoDiscount &&
                  <span><br />statt {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.totalNoDiscount)}</span>
                }

              </MDBCol>
            </MDBRow>
            <hr />

            <MDBRow>
              <MDBCol sm="8">
                <strong>Beglichener Betrag</strong>
              </MDBCol>
              <MDBCol sm="4">
                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.paid)}
              </MDBCol>
            </MDBRow>




            {this.props.order.totals.due > 0 &&
              <>
              <hr />
              <MDBRow>
                <MDBCol sm="8">
                  <strong>Offener Betrag</strong>
                </MDBCol>
                <MDBCol sm="4">
                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.due)}
                </MDBCol>
              </MDBRow>
              </>
            }

            {this.props.order.totals.due < 0 &&
              <>
              <hr />
              <MDBRow>
                <MDBCol sm="8">
                  <strong>Wechselgeld</strong>
                </MDBCol>
                <MDBCol sm="4">
                  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'CHF' }).format(this.props.order.totals.due)}
                </MDBCol>
              </MDBRow>
              </>
            }

          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}


export default Totals;
