import { Component } from 'react';

class TermsToSelect extends Component {
  constructor(props) {
    super(props);
  }

  static convertToSelect(terms) {
    var termsFilter = new Array();
    if(terms) {
      Object.entries(terms).forEach(function(item, key){
        var optionItem = {
          label: item[1].name[0].value,
          value: item[0]
        }
        termsFilter.push(optionItem);
        //console.log(item);
      });

      return termsFilter;
    }
  }


  static getSelectedValue(termsFilter, terms) {
    let defaultTermsValue = new Array();
    if(terms) {
      Object.entries(terms).forEach(function(item, key){
        console.log(item);
        if(termsFilter.find(x => x.value === (item[1].tid[0].value).toString())) {
          let selectedItem = { label: termsFilter.find(x => x.value === (item[1].tid[0].value).toString()).label, value: (item[1].tid[0].value).toString() };
          defaultTermsValue.push(selectedItem);
        }
      })
    }
    return defaultTermsValue;
  }


}
 export default TermsToSelect;
