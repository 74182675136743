import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBStepper,
  MDBStep,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBContainer
} from 'mdbreact';


import moment from 'moment-timezone';

import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'; // or 'rsuite/dist/styles/rsuite-default.css'

import * as dateFns from "date-fns";

/** import config **/
import config from '../../../config';


class EvaluationDateStep extends Component {

  constructor(props) {
    super(props);

    this._onDateSelect = this._onDateSelect.bind(this);
  }


  _onDateSelect(e) {
    var component = this;


    var filters = this.props.stepper.state.filters;
    filters['field_from'] = new Date(e[0].setHours('00','00','00'));
    filters['field_to'] = new Date(e[1].setHours('23','59','50'));

    filters['field_from'] = filters['field_from'].toJSON();
    filters['field_to'] = filters['field_to'].toJSON();

    this.props.stepper._updateSearchFilter(filters);
  }


  render() {
    return (
      <section id="evaluation-date">
        <DateRangePicker
          defaultValue={[this.props.stepper.state.filters.field_from, this.props.stepper.state.filters.field_to]}
          showOneCalendar={false}
          cleanable={false}
          onChange={this._onDateSelect}
          ranges={[
            {
              label: 'Heute',
              value: [dateFns.startOfDay(new Date()), dateFns.endOfDay(new Date())]
            },
            {
              label: 'Gestern',
              value: [
                dateFns.startOfDay(dateFns.addDays(new Date(), -1)),
                dateFns.endOfDay(dateFns.addDays(new Date(), -1))
              ]
            },
            {
              label: 'Letzten 7 Tage',
              value: [dateFns.startOfDay(dateFns.subDays(new Date(), 6)), dateFns.endOfDay(new Date())]
            },
            {
              label: 'Dieser Monat',
              value: [dateFns.startOfMonth(new Date()), dateFns.endOfMonth(new Date())]
            },
            {
              label: 'Letzter Monat',
              value: [dateFns.startOfMonth(dateFns.subMonths(new Date(), 1)), dateFns.endOfMonth(dateFns.subMonths(new Date(), 1))]
            },
            {
              label: 'Aktuelle Jahr',
              value: [dateFns.startOfYear(new Date()), dateFns.endOfYear(new Date())]
            },
            {
              label: 'Vergangendes Jahr',
            value: [dateFns.startOfYear(dateFns.subYears(new Date(), 1)), dateFns.endOfYear(dateFns.subYears(new Date(), 1))]
            }
          ]}
        />
      </section>
    );
  }
}

export default EvaluationDateStep;
