import React, { Component } from 'react';
import Pager from 'react-pager';
import OrderResultRow from "./OrderResultRow";
import OrderActions from "./actions/OrderActions";
import Image from "../image/Image";
import OrderFilter from "./OrderFilter";
import OrderScan from "./OrderScan";
import OrderForm from "./OrderForm";
import PermissionHelper from "../../../helpers/PermissionHelper";
import { Empty, Spin } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';


class OrderSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      collectionLoaded: false,
      result: [],
      page: 0,
      total: 0,
      visiblePage: 5,
      filters: {
        field_searchterm: '',
        field_order_status: '',
        field_cash_register_session: this.props.cashRegisterSessionId ? this.props.cashRegisterSessionId :  null,
        field_location: localStorage.getItem('selectedLocation'),
        field_sort: 'nfd.changed',
        field_sort_direction: 'desc',
        field_limit: 10
      },
      sortOptions: [
        {
          label: 'Geändert',
          value: 'nfd.changed'
        },
        {
          label: 'Titel',
          value: 'nfd.title'
        }
      ]
    }


    var orderStatusArray = null;
    var taxRulesData = null;

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadOrderCollection();
    });
  }

  componentDidMount(){
    let component = this;

    var orderFilter = JSON.parse(localStorage.getItem('orderFilter'));

    var selectedLocation = localStorage.getItem('selectedLocation');

    if(orderFilter) {
      orderFilter.filters.field_location = selectedLocation;
      component.setState({
        filters: orderFilter.filters
      }, function() {
        component.loadOrderCollection();
      });
    } else {
      component.loadOrderCollection();
    }
  }

  loadOrderCollection() {


      var component = this;

      component.setState({
        collectionLoaded: false,
      });

      /** build filter query **/

      OrderActions._get('All', this.state.filters, this.state.page).then(response => {

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }



        var taxRulesData = response.data.taxRulesData;


        var orderStatusArray = response.data.orderStatusArray;


        component.setState({
          result: result,
          collectionLoaded: true,
          total: response.data.total,
          orderStatusArray: orderStatusArray,
          taxRulesData: taxRulesData,
          loaded: true,
        });



      });
  }

  render() {
    let component = this;




    return (
      <>


      {/*<OrderForm searchComponent={this} />
      <hr />*/}


      {this.state.loaded &&
        <>
          <OrderFilter searchComponent={component} />
          {/*<OrderScan searchComponent={component} />*/}
          <hr />
        </>
      }




        {!this.state.loaded &&
          <div className="text-center">
            <div className="text-center"><MDBSpinner key={'order-loader'} red /></div>
          </div>
        }

        {component.state.loaded && (
          <>

          {/*<OrderFilter searchComponent={this} /><hr />*/}

            {component.state.result.length > 0 && (


              <MDBTable responsive hover striped className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Bestellnummer
                    </th>
                    <th className='th-lg'>
                      erstellt
                    </th>
                    <th className='th-lg'>
                      aktualisiert
                    </th>
                    <th className='th-lg'>
                      Status
                    </th>
                    <th className='th-lg'>
                      Kunde
                    </th>
                    <th className='th-lg'>
                      Verkäufer
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      console.log(row)
                      return (
                        <OrderResultRow result={row[1]}  key={'order-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.filters.field_limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default OrderSearch;
