import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBBadge
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';

import { Editor } from '@tinymce/tinymce-react';


import CommentActions from "./actions/CommentActions";
import Image from "../image/Image";

import moment from 'moment';
import 'moment/locale/de';


class CommentImportantCounter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      filters: {
        entity_id: this.props.entityId,
        entity_type: this.props.entityType,
      },
      page: 0
    }

  }

  componentDidMount() {
    var component = this;


    /** build filter query **/
    CommentActions._get('All', this.state.filters, this.state.page).then(response => {
        console.log(response.data.countImportant);
        component.setState({
          counter: response.data.countImportant
        });
    });
  }



  render() {
    let component = this;
    return (
      <>
      {this.state.counter > 0 &&
        <MDBBadge color="danger" className="ml-2">{this.state.counter}</MDBBadge>
      }
      </>
    );
  }
}

export default CommentImportantCounter;
