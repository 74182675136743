import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';

import { Modal, Select, Cascader } from 'antd';

import TreatmentDurationAdjustActions from "./actions/TreatmentDurationAdjustActions";

import moment from 'moment';
import 'moment/locale/de';


class TreatmentDurationAdjustForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      modal: false,
      entity: this.props.entity,
      data: {
        field_employee: this.props.employeeId,
        field_product: this.props.entity ? this.props.entity.field_product[0].target_id : "",
        field_duration: this.props.entity ? this.props.entity.field_duration[0].value : "",
      }
    }


    this._onInputChange = this._onInputChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onCascaderChange = this._onCascaderChange.bind(this);
    this.convertToTime = this.convertToTime.bind(this);
  }


  componentDidMount() {
    var component = this;


    console.log(this.props.searchComponent);

  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      data: this.state.data
    }

    if(!this.state.entity) {
      TreatmentDurationAdjustActions._post(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadTreatmentDurationAdjustCollection();
        });
    } else {
      values.nid = this.state.entity.nid[0].value;
      TreatmentDurationAdjustActions._patch(values)
        .then(response => {
          component._onFormReset();
          component._onToggleModal();
          component.props.searchComponent.loadTreatmentDurationAdjustCollection();
        });
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      data: {
        field_cash_register: this.props.nid,
        field_poiid: ''
      },
      nid: false
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onInputChange(e) {

    var name = e.target.name;
    var value = e.target.value;

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }

    //console.log(e.target.value);

    this.setState(prevState => ({
      data: {                   // object that we want to update
          ...prevState.data,    // keep all other key-value pairs
          [name]: value  // update the value of specific key
      }
    }))

  }

  _onChangeHandleFile = (file) => {
    var component = this;

    this.setState({
      file: file
    });

    var extension = file.name.split('.').pop().toLowerCase();
    //console.log(extension);

    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         TreatmentDurationAdjustActions._uploadFile(uint8, extension).then(response => {

           var name = 'field_image';

           component.setState(prevState => ({
             data: {                   // object that we want to update
                 ...prevState.data,    // keep all other key-value pairs
                 [name]: response.data.fid[0].value  // update the value of specific key
             }
           }))


         });
    }, false);


    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        fileUrl: URL.createObjectURL(file)
      });
    }
  }

  convertToTime(num) {
    let hours = Math.floor(num / 60);
    let minutes = num % 60;
    return hours + ":" + minutes;
  }

  _onCascaderChange(value, name) {
     let component = this;
     //console.log(value);
     //console.log(name);
     let data = this.state.data;
     data[name] = value;
     let selectedProduct;

     selectedProduct = Object.entries(component.props.searchComponent.state.productsCascaderFilter).filter(category => {
       let child;
       child =  Object.entries(category[1].children).filter(childItem => {
         if(childItem[1].value == value) {
           console.log(childItem[1]);
           console.log(data);
           selectedProduct = childItem[1].label;
         }
       })
     })

     component.setState({
          data: data
      },() => {
        component.setState(prevState => ({
          data: data
        }))
      });
   }

  render() {
    let component = this;


    let defaultProduct = [];
    let selectedCategory = false;
    let selectedProduct = false;
    if(component.props.searchComponent.state.productsCascaderFilter) {
      defaultProduct = Object.entries(component.props.searchComponent.state.productsCascaderFilter).filter(category => {
        let child =  Object.entries(category[1].children).filter(childItem => {
          if(childItem[1].value == component.state.data.field_product) {
            selectedCategory = category[1].value;
            selectedProduct = childItem[1].value;
          }
        })
      })
    }

    if(selectedCategory && selectedProduct) {
      defaultProduct = [selectedCategory, selectedProduct];
    } else {
      defaultProduct = false;
    }

    return (

      <>



        {!this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary" className="btn-add"><MDBIcon icon="plus mr-2" /> Neues Anpassung erfassen</MDBBtn>
        }

        {this.props.entity &&
          <MDBBtn onClick={this._onToggleModal} color="primary" className="btn-edit"><MDBIcon far icon="edit" /></MDBBtn>
        }

        <Modal destroyOnClose={true} title="Anpassung" visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

            <form id="treatmentdurationadjust-add-form" onSubmit={this._onSubmit}>

              <MDBRow>
                <MDBCol>
                  <MDBInput
                    outline
                    name='field_duration'
                    label='Duration'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    onChange={this._onInputChange}
                    value={this.state.data.field_duration}
                  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                  <MDBCol>

                      <Cascader options={component.props.searchComponent.state.productsCascaderFilter} defaultValue={defaultProduct}
                        onChange={(value) => {
                            this._onCascaderChange(value[1], 'field_product');
                        }}
                        placeholder="Leistung"
                      />

                  </MDBCol>
                </MDBRow>


              <MDBRow>
                <MDBCol>
                  <div className="form-action-bar">
                    {!this.state.entity &&
                      <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2 hide"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                    }
                    <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                  </div>
                </MDBCol>
              </MDBRow>

            </form>
          </Modal>
      </>


    );
  }
}

export default TreatmentDurationAdjustForm;
