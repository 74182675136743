import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";

import QrReader from 'react-qr-reader'
import GiftCard from '../../../assets/images/qrCard.png' // relative path to image

import BarcodeReader from 'react-barcode-reader';

import { Modal } from 'antd';

import UserCardActions from "./actions/UserCardActions";

import moment from 'moment';
import 'moment/locale/de';


class UserCardForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nid: false,
      field_customer: this.props.searchComponent.props.customerId,
      field_user: this.props.uid,
      field_card_secret_key: '',
      loaded: true,
      modal: false,
    }

    this.handleScan = this.handleScan.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
  }



  handleScan(data){


    var component = this;

    component.setState({
      field_card_secret_key: data
    }, function() {
    });


  }
  handleError(err){
    console.error(err)
  }

  _onHandleScan = data => {
    if (data) {
      this.setState({
        field_card_secret_key: data,
      })
    }
  }

  _onHandleError = err => {
    console.error(err)
  }


  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      nid: this.state.nid,
      field_user: this.state.field_user,
      field_customer: this.state.field_customer,
      field_card_secret_key: this.state.field_card_secret_key,
    }

    if(!this.state.nid) {
      UserCardActions._post(values)
        .then(response => {
          component.props.searchComponent.loadUserCardCollection();
          component._onFormReset();
          component._onToggleModal();

        });
    } else {
      UserCardActions._patch(values)
        .then(response => {



          component.props.searchComponent.loadUserCardCollection();
          component._onFormReset();
          component._onToggleModal();


        });
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      field_card_secret_key: '',
      nid: false
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    let component = this;
    return (

      <>
      <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neue Karte erfassen</MDBBtn>

        <Modal title="Karte Scannen" visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>


            <form id="usercard-add-form" onSubmit={this._onSubmit}>
              <MDBRow>
                <MDBCol size="12">



                  <div className="row">
                    <div className="col-md-12">



                      <div  className={ component.state.field_card_secret_key ? 'hide' : '' }>
                        <BarcodeReader
                          onError={this.handleError}
                          onScan={this.handleScan}
                          />
                        <p>{this.state.field_card_secret_key}</p>
                      </div>

                      {component.state.field_card_secret_key &&
                        <MDBRow>
                          <MDBCol>

                            <MDBAlert color="success" >
                              {'Karte wurde erkannt. Bitte speichern'}
                            </MDBAlert>

                          </MDBCol>
                        </MDBRow>
                      }




                    </div>

                  </div>






                </MDBCol>
                <MDBCol size="12">


                 <MDBRow>
                   <MDBCol>


                       {!this.state.field_card_secret_key &&
                         <MDBBtn color="primary" type="submit" disabled><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                       }

                       {this.state.field_card_secret_key &&
                         <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                       }

                   </MDBCol>
                 </MDBRow>

                </MDBCol>
              </MDBRow>
            </form>
          </Modal>
      </>


    );
  }
}

export default UserCardForm;
