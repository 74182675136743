import React, {
  Component
} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBTimePicker,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSelect
} from "mdbreact";

import TaskActions from "./actions/TaskActions";
import PermissionHelper from "../../../helpers/PermissionHelper";

import { MDBDatePickerV5 } from 'mdbreact';

import { Modal } from 'antd';

import moment from 'moment';
import 'moment/locale/de';


class TaskForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasSubmitted: false,
      modal: false,
      body: '',
      field_status: 0,
      field_date: new Date(),
      field_time: "12:00",
      field_location: (this.props.route == '/taskteam') ? [localStorage.getItem('selectedLocation')] : null,
      loaded: true,
      locationsFilter: this.props.locationsFilter,
    }

    this.datepickerRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onGetDatePickerValue = this._onGetDatePickerValue.bind(this);
    this._onGetTimePickerValue = this._onGetTimePickerValue.bind(this);
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onGetDatePickerValue = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      'field_date': value,
    }))
  }

  _onGetTimePickerValue(value) {
    //console.log(value);
    this.setState(prevState => ({
      'field_time': value,
    }))
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    this.setState(prevState => ({
      loaded: false,
      hasSubmitted: true,
    }))

    let values = {
      field_employee: this.props.searchComponent.state.filters.field_employee,
      field_date: this.state.field_date,
      field_time: this.state.field_time,
      field_location: this.state.field_location,
      body: this.state.body
    }
    //console.log(values);
    TaskActions._post(values)
      .then(response => {

        component.setState(prevState => ({
            loaded: true,
            hasSubmitted: false,
        }))

        component.props.searchComponent.loadTaskCollection();
        component._onToggleModal();
        component.setState(prevState => ({
          loaded: true,
          body: ''
        }))
      });
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onGetPickerValue = (value) => {
    if(value == 'Invalid Date'  || this.datepickerRef.current.state.inputValue == '') {
      return;
    }
    value.setDate(value.getDate() + 1);

    console.log(value);

    var component = this;
    var values = {
      nid: this.props.result.recordcard.nid[0].value,
      field_date: value
    }


  }

  render() {


    var locationTaskPermission = true;


    if(!PermissionHelper._hasPermission('config location task') && this.props.route == '/taskteam') {
      locationTaskPermission = false;
    }

    var component = this;
    return (
      <>
      {!this.state.entity &&
        <>
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neue Aufgabe erfassen</MDBBtn>
          <hr />
        </>
      }


      <Modal destroyOnClose={true} title="Aufgabe" visible={this.state.modal} onOk={this._onToggleModal} onCancel={this._onToggleModal} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>

          <form id="task-add-form" onSubmit={this._onSubmit}>
            <MDBRow>
              <MDBCol size="12">


                <MDBRow>
                  <MDBCol size="6">

                    <div className="md-form form-group md-outline">
                      <MDBInput outline type="date" id="field_date" label="Datum" name="field_date" value={this.state.field_date} onChange={this._onInputChange} required />
                    </div>

                  </MDBCol>
                  <MDBCol size="6">
                    <div className="md-form form-group md-outline">
                      <MDBInput outline type="time" id="field_time" label="Zeit" name="field_time" value={this.state.field_time} onChange={this._onInputChange} required />
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol size="12">
                <MDBInput outline type="textarea" label="Bemerkung" rows="3" name="body" value={this.state.body} onChange={this._onInputChange} required />
              </MDBCol>
            </MDBRow>

            <div className="form-action-bar">
              <MDBBtn color="primary" disabled={this.state.hasSubmitted ? true : false}  type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
            </div>

          </form>
        </Modal>




      </>
    );
  }
}

export default TaskForm;
