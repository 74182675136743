import React, {
  Component
} from 'react';

import EmployeeResultRow from "./EmployeeResultRow";
import Pager from 'react-pager';
import EmployeeFilter from "./EmployeeFilter";
import PermissionHelper from "../../../helpers/PermissionHelper";

import NodesToSelect from "../../../helpers/NodesToSelect";
import RolesToSelect from "../../../helpers/RolesToSelect";
import TermsToSelect from "../../../helpers/TermsToSelect";

import EmployeeActions from "./actions/EmployeeActions";
import EmployeeForm from "./EmployeeForm";
import Select from 'react-select'
import { Empty } from 'antd';

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';

import config from '../../../config';



class EmployeeSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      collectionLoaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      roles: [],
      rolesFilter: [],
      locationsFilter: [],
      locations: [],
      treatmentTypeFilter: [],
      treatmentTypes: [],
      filters: {
        field_location: localStorage.getItem('selectedLocation'),
        field_status: 1,
        field_role: null,
        field_sort: 'ufd.changed',
        field_sort_direction: 'desc',
        field_limit: 10
      },
      sortOptions: [
        {
          label: 'Geändert',
          value: 'ufd.changed'
        },
        {
          label: 'Nachname',
          value: 'uf_lastname.field_lastname_value'
        }
      ]
    }

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadEmployeeCollection();
    });
  }

  componentDidMount() {
    this.loadEmployeeCollection();
    //console.log(this);
  }

  loadEmployeeCollection() {


      let component = this;

      component.setState({
        collectionLoaded: false
      });

      /** build filter query **/
      EmployeeActions._get('All', this.state.filters, this.state.page).then(response => {
        //  console.log(response.data.entity);
        //console.log(response.data.result);


        let result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }


        let locations = response.data.locations;
        let locationsFilter = NodesToSelect.convertToSelect(locations);
        if(this.state.locationsFilter.length == 0) {
          component.setState({
            locationsFilter: locationsFilter,
            locations: locations,
          });
        }

        let roles = response.data.roles;
        let rolesFilter = RolesToSelect.convertToSelect(roles);
        if(this.state.rolesFilter.length == 0) {
          component.setState({
            rolesFilter: rolesFilter,
            roles: roles,
          });
        }


        let treatmentTypes = response.data.treatmentTypes;
        let treatmentTypeFilter = TermsToSelect.convertToSelect(treatmentTypes);
        if(this.state.treatmentTypeFilter.length == 0) {
          component.setState({
            treatmentTypeFilter: treatmentTypeFilter,
            treatmentTypes: treatmentTypes,
          });
        }





        component.setState({
          result: result,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });

      });
  }

  render() {
    let component = this;
    return (
      <>

        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'employee-loader'} red /></div>
        }


        {component.state.loaded && (
          <>
            {PermissionHelper._hasPermission('restful post Employee') === true &&
              <>
                <EmployeeForm searchComponent={this} ref={this.formRef} />
                <hr />
              </>
            }

            <EmployeeFilter searchComponent={component} />
            <hr />
            { component.state.result.length > 0 && (
              <MDBTable responsive hover striped className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Mitarbeiternummer
                    </th>

                    <th className='th-lg'>
                      Vorname
                    </th>
                    <th className='th-lg'>
                      Nachname
                    </th>
                    <th className='th-lg'>
                      E-Mail
                    </th>
                    <th className='th-lg'>
                      Mobil
                    </th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <EmployeeResultRow result={row[1]}  key={'employee-result-view-' + row[1].uid  + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>
            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.filters.field_limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />


              </>
            )}
          </>
        )}

      </>
    );
  }
}

export default EmployeeSearch;
