import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";

import NewsletterActions from "./actions/NewsletterActions";

import moment from 'moment';
import 'moment/locale/de';

import { Tooltip, Progress } from 'antd';

import { LazyLog } from 'react-lazylog';


class NewsletterSend extends Component {

  constructor(props) {
    super(props);
    this.state = {
      responseLog: 'Start',
      offset: this.props.node.field_newsletter_log.length,
      isSending: false
    }
  }

  componentDidMount() {
    let component = this;
    let responseLog = this.state.responseLog;
    this.props.node.field_newsletter_log.map((number, i) => {
      responseLog = responseLog + '\n' + 'Nummer: ' + number.value
    })


    component.setState({
        responseLog:  responseLog
    });
  }

  _onStart() {

    let component = this;


    component.setState({
        isSending:  true
    });

    component._onSend();


  }

  _onSend() {
    let component = this;


    var values = {
      'nid': this.props.node.nid[0].value,
      'action': 'send',
      'numbers': this.props.numbers,
      'offset': this.state.offset
    }

    NewsletterActions._post(values)
      .then(response => {

        component.setState({
            offset:component.state.offset + 1,
            responseLog: component.state.responseLog + '\n' + 'Nummer: ' + response.data.message
        },() => {
            if(component.state.offset < component.props.numbers.length && component.state.isSending) {
              component._onSend();
            }

            if(component.state.offset == component.props.numbers.length) {
              component.setState({
                  isSending:  false
              });
            }
        });

      }).catch(error => {
      });
  }

  _onStop() {
    let component = this;
    component.setState({
        isSending:  false
    });
  }

  render() {
    return (
      <>


          <Progress percent={parseInt(this.state.offset / this.props.numbers.length * 100)} />

          <div id="newsletter-log">
            <LazyLog follow={true} extraLines={1} text={this.state.responseLog} caseInsensitive />
          </div>

          <hr />


          {!this.state.isSending && 
            <MDBBtn color="primary" onClick={() => { this._onStart() }}><MDBIcon far icon="save" className="mr-2" /> Newsletter senden</MDBBtn>
          }

          {this.state.isSending &&
            <MDBBtn color="primary" onClick={() => { this._onStop() }}><MDBIcon far icon="save" className="mr-2" /> Newsletter stoppen</MDBBtn>
          }




      </>
    );
  }
}

export default NewsletterSend;
