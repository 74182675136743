import React, { Component } from 'react';


/** import config **/
import config from '../../../config';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBStepper,
  MDBStep,
  MDBBtn,
  MDBInput,
  MDBSpinner,
  MDBDatePickerV5,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBIcon,
  MDBPopover,
  MDBPopoverHeader,
  MDBPopoverBody,
  MDBSwitch
} from "mdbreact";
import { connect } from 'react-redux';
import { withTranslation, initReactI18next } from "react-i18next";

import moment from 'moment-timezone';

import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'; // or 'rsuite/dist/styles/rsuite-default.css'

import * as dateFns from "date-fns";



class DashboardFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }

    this._onDateSelect = this._onDateSelect.bind(this);
    this._onHandleCheckbox = this._onHandleCheckbox.bind(this);
 }

  componentDidMount() {
    console.log(this);
  }


  _onDateSelect(e) {
    var component = this;


    var filters = this.props.dashboard.state.filters;
    filters['field_from'] = new Date(e[0].setHours('00','00','00'));
    filters['field_to'] = new Date(e[1].setHours('23','59','50'));

    this._updateSearchFilter(filters);
  }



  _updateSearchFilter(filters) {

    var component = this;

    filters['field_from'] = new Date(filters['field_from']);
    filters['field_to'] = new Date(filters['field_to']);

    //console.log(filters);
    component.props.dashboard.setState({
        filters: filters,
    }, () => {

      var dashboardFilter = component.props.dashboard.state;
      localStorage.setItem('dashboardFilter', JSON.stringify(dashboardFilter));
      component.props.dashboard.loadData();

    });
  }

  _onHandleCheckbox = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var filters = this.props.dashboard.state.filters;
    filters[name] = value;

    this._updateSearchFilter(filters);
  }



  render() {


    return (
      <section id="dashboard-filter">

        <div className="d-flex">
          {this.props.dashboard.state.filters.field_from &&
            <DateRangePicker
              defaultValue={[this.props.dashboard.state.filters.field_from, this.props.dashboard.state.filters.field_to]}
              showOneCalendar={false}
              cleanable={false}
              onChange={this._onDateSelect}
              ranges={[
                {
                  label: 'Heute',
                  value: [dateFns.startOfDay(new Date()), dateFns.endOfDay(new Date())]
                },
                {
                  label: 'Gestern',
                  value: [
                    dateFns.startOfDay(dateFns.addDays(new Date(), -1)),
                    dateFns.endOfDay(dateFns.addDays(new Date(), -1))
                  ]
                },
                {
                  label: 'Letzten 7 Tage',
                  value: [dateFns.startOfDay(dateFns.subDays(new Date(), 6)), dateFns.endOfDay(new Date())]
                },
                {
                  label: 'Dieser Monat',
                  value: [dateFns.startOfMonth(new Date()), dateFns.endOfMonth(new Date())]
                },
                {
                  label: 'Letzter Monat',
                  value: [dateFns.startOfMonth(dateFns.subMonths(new Date(), 1)), dateFns.endOfMonth(dateFns.subMonths(new Date(), 1))]
                },
                {
                  label: 'Aktuelle Jahr',
                  value: [dateFns.startOfYear(new Date()), dateFns.endOfYear(new Date())]
                },
                {
                  label: 'Vergangendes Jahr',
                value: [dateFns.startOfYear(dateFns.subYears(new Date(), 1)), dateFns.endOfYear(dateFns.subYears(new Date(), 1))]
                }
              ]}
            />
          }


        <MDBInput label="Standort ignorieren" type="checkbox" checked={this.props.dashboard.state.filters.field_ignore_location} name="field_ignore_location" id="ignore-location"  onChange={this._onHandleCheckbox} />
        </div>

      </section>
    );
  }
}

export default DashboardFilter;
