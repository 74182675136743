import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';

import Dashboard from './modules/dashboard/Dashboard';
import ProductOverview from './modules/product/ProductOverview';
import CustomerOverview from './modules/customer/CustomerOverview';
import CustomerDetail from './modules/customer/CustomerDetail';
import CategoryOverview from './modules/category/CategoryOverview';
import StatisticOverview from './modules/statistic/StatisticOverview';
import CashRegisterOverview from './modules/cashregister/CashRegisterOverview';
import CashRegisterDetail from './modules/cashregister/CashRegisterDetail';
import CashRegisterSessionDetail from './modules/cashregistersession/CashRegisterSessionDetail';
import OrderOverview from './modules/order/OrderOverview';
import OrderDetail from './modules/order/OrderDetail';
import GiftCardOverview from './modules/giftcard/GiftCardOverview';
import SubscriptionOverview from './modules/subscription/SubscriptionOverview';
import NewsOverview from './modules/news/NewsOverview';
import NewsletterOverview from './modules/newsletter/NewsletterOverview';

import EmployeeOverview from './modules/employee/EmployeeOverview';
import VacationQuotaOverview from './modules/vacation_quota/VacationQuotaOverview';
import VacationOverview from './modules/vacation/VacationOverview';
import TaskOverview from './modules/task/TaskOverview';
import TaskTeamOverview from './modules/task/TaskOverview';

import LocationOverview from './modules/location/LocationOverview';


import EvaluationOverview from './modules/evaluation/EvaluationOverview';


class Routes extends React.Component {
  render() {
    return (
      <>
        <PrivateRoute path='/' exact component={Dashboard}  />
        <PrivateRoute path='/dashboard' exact component={Dashboard} />
        <PrivateRoute path='/product' exact component={ProductOverview} />
        <PrivateRoute path='/category' exact component={CategoryOverview} />
        <PrivateRoute path='/statistic' exact component={StatisticOverview} />

        <PrivateRoute path='/giftcard' exact component={GiftCardOverview} />

        <PrivateRoute path='/subscription' exact component={SubscriptionOverview} />

        <PrivateRoute path='/cashregister' exact component={CashRegisterOverview} />
        <PrivateRoute path='/cashregister/:id' exact component={CashRegisterDetail} />

        <PrivateRoute path='/cashregistersession/:id' exact component={CashRegisterSessionDetail} />

        <PrivateRoute path='/order' exact component={OrderOverview} />
        <PrivateRoute path='/order/:id' exact component={OrderDetail} />

        <PrivateRoute path='/employee' exact component={EmployeeOverview} />

        <PrivateRoute path='/customer' exact component={CustomerOverview} />
        <PrivateRoute path='/customer/:id' exact component={CustomerDetail} />

        <PrivateRoute path='/evaluation' exact component={EvaluationOverview} />

        <PrivateRoute path='/vacation' exact component={VacationOverview} />

        <PrivateRoute path='/vacationquota' exact component={VacationQuotaOverview} />

        <PrivateRoute path='/task' exact component={TaskOverview} />
        <PrivateRoute path='/taskteam' exact component={TaskTeamOverview} />

        <PrivateRoute path='/news' exact component={NewsOverview} />

        <PrivateRoute path='/newsletter' exact component={NewsletterOverview} />

        <PrivateRoute path='/location' exact component={LocationOverview} />

      </>
    );
  }
}

export default Routes;
