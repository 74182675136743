import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';
import PermissionHelper from "../../../helpers/PermissionHelper";

import Select from 'react-select'

import LimitSelect from "../misc/LimitSelect";

import QrReader from 'react-qr-reader'
import OrderReceipt from '../../../assets/images/gutscheinkarte-1.png' // relative path to image

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter
} from 'mdbreact';

class OrderScan extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      order_number: ''
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }


  _onHandleScanError = error => {
    var component = this;
    console.error(error);
    if(error.name == "NotFoundError" || error.name == "NotAllowedError" || error.name == "OverconstrainedError") {
      var data = '0';
      alert(error);
    }
  }

  _onHandleScanSuccess = data => {
    var component = this;

    console.log(data);

    var filters = component.props.searchComponent.state.filters;
    var filtersTemp = filters;

    var filtersTemp = JSON.parse(JSON.stringify(filters));

    filtersTemp['field_searchterm'] = data;

    filtersTemp['field_order_status'] = null;
    filtersTemp['field_location'] = null;
    filtersTemp['field_cash_register_session'] = null;


    if(data != null) {
      component.props.searchComponent.setState({
        filters: filtersTemp
      }, function() {

        component.props.searchComponent.loadOrderCollection();
        filters['field_searchterm'] = '';

        component.props.searchComponent.setState({
          filters: filters
        }, function() {

          component.toggle();

        });



      });

    }



  }


  render() {
    let component = this;
    return (
      <div className="order-scan">
        <MDBBtn color="primary" onClick={this.toggle}>
          <MDBIcon icon="qrcode" />
        </MDBBtn>
      <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">
        <MDBModalHeader toggle={this.toggle}>Kassenzettel Scannen</MDBModalHeader>
        <MDBModalBody>

          <div className="row">
            <div className="col-sm-6">
              <QrReader
                className="qr-scanner"
                delay={500}
                onError={this._onHandleScanError}
                onScan={this._onHandleScanSuccess}
                style={{ width: '100%', padding: '20px' }}
                facingMode={'front'}
                showViewFinder={true}
                resolution={1024}
              />
            </div>
            <div className="col-md-6">
              <FontAwesomeIcon icon={AllLightIcons.faQrcode} />
            </div>
          </div>


        </MDBModalBody>
      </MDBModal>
      </div>
    )
  }

}

export default OrderScan;
