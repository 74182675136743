import React, { Component } from 'react';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBStepper,
  MDBStep,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBContainer
} from 'mdbreact';


/** import config **/
import config from '../../../config';


class EvaluationLocationStep extends Component {

  constructor(props) {
    super(props);

    this._onHandleCheckbox = this._onHandleCheckbox.bind(this);
  }


  _onHandleCheckbox = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var filters = this.props.stepper.state.filters;
    filters[name] = value;

    this.props.stepper._updateSearchFilter(filters);

  }




  render() {
    return (
      <section id="evaluation-date">
        <MDBInput label="Standort ignorieren" type="checkbox" checked={this.props.stepper.state.filters.field_ignore_location} name="field_ignore_location" id="ignore-location"  onChange={this._onHandleCheckbox} />

      </section>
    );
  }
}

export default EvaluationLocationStep;
