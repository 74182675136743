import React, {
  Component
} from 'react';

import Pager from 'react-pager';
import VacationFilter from "./VacationFilter";
import PermissionHelper from "../../../helpers/PermissionHelper";
import VacationActions from "./actions/VacationActions";
import VacationForm from "./VacationForm";
import VacationQuotaForm from "../vacation_quota/VacationQuotaForm";
import { Empty } from 'antd';

import Timeline from '../timeline/Timeline'

/** import styles **/
import '../../../assets/css/sections/vacation.css';
import '../timeline/scss/style.css';


import { START_YEAR, NUM_OF_YEARS, NUM_OF_TRACKS, MAX_NUM_OF_SUBTRACKS } from './constants.jsx'

import { buildTimebar, buildTrack, buildElements, buildElement } from './builders'

import { fill } from './utils'


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';

import config from '../../../config';


const MIN_ZOOM = 2
const MAX_ZOOM = 50


class VacationSearch extends Component {

  constructor(props) {
    super(props);

    const tracksById = fill(0).reduce((acc, i) => {
      const track = buildTrack(i + 1)
      acc[track.id] = track
      return acc
    }, {})

    this.state = {
      open: false,
      zoom: 30,
      // eslint-disable-next-line react/no-unused-state
      tracksById: tracksById,
      tracks: Object.values(tracksById),
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      rolesFilter: [],
      locationsFilter: [],
      vacationCategoryFilter: [],
      employeeFilter: [],
      vacationCategoryOptions: [],
      logindaysFilter: [],
      locations: [],
      roles: [],
      filters: {
        field_location: localStorage.getItem('selectedLocation'),
        field_vacation_category: null,
        field_role: null,
        field_year: new Date().getFullYear(),
        field_status: 1,
        field_sort: 'ufd.changed',
        field_sort_direction: 'desc'
      },
      sortOptions: [
        {
          label: 'Geändert',
          value: 'ufd.changed'
        },
        {
          label: 'Nachname',
          value: 'uf_lastname.field_lastname_value'
        }
      ],
      yearOptions: [
        {
          label: (new Date().getFullYear()-1),
          value: (new Date().getFullYear()-1)
        },
        {
          checked: true,
          label: (new Date().getFullYear()),
          value: (new Date().getFullYear())
        },
        {
          label: (new Date().getFullYear()+1),
          value: (new Date().getFullYear()+1)
        }
      ]
    }

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadVacationCollection();
    });
  }

  componentDidMount() {
    this.loadVacationCollection();
    //console.log(this);
  }

  loadVacationCollection() {


      var component = this;

      component.setState({
        collectionLoaded: false
      });

      /** build filter query **/
      VacationActions._get('All', this.state.filters, this.state.page).then(response => {
        //  console.log(response.data.entity);
        //console.log(response.data);

        var result = [];
        if(response.data.result) {
          var employeeFilter = new Array();
          result = Object.entries(response.data.result);

          Object.entries(response.data.result).forEach(function(item, key){
            var optionItem = {
              label: item[1].user.field_firstname[0].value + ' ' + item[1].user.field_lastname[0].value,
              value: parseInt(item[1].user.uid[0].value )
            }
            employeeFilter.push(optionItem);
            component.setState({
              employeeFilter: employeeFilter,
            });
            //console.log(item);
          });

        }



        var locations = response.data.locations;
        var locationsFilter = new Array();
        if(locations) {
          Object.entries(locations).forEach(function(item, key){
            var optionItem = {
              label: item[1].title[0].value,
              value: item[1].nid[0].value
            }

            if(localStorage.getItem('selectedLocation') == item[0]) {
              optionItem.checked = true;
            }
            locationsFilter.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.locationsFilter.length == 0) {
          component.setState({
            locationsFilter: locationsFilter,
            locations: locations,
          });
        }



        var vacationCategories = response.data.vacationCategoryOptionsNew;
        var vacationCategoryFilter = new Array();
        if(locations) {
          Object.entries(vacationCategories).forEach(function(item, key){
            var optionItem = {
              label: item[1].name[0].value,
              value: item[1].tid[0].value
            }

            vacationCategoryFilter.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.vacationCategoryFilter.length == 0) {
          component.setState({
            vacationCategories: vacationCategories,
            vacationCategoryFilter: vacationCategoryFilter,
          });
        }



        if(this.state.vacationCategoryOptions.length == 0) {

          component.setState({
            vacationCategoryOptions: response.data.vacationCategoryOptions
          });

          component.setState({
            vacationCategory: response.data.vacationCategory
          });
        }


        var roles = response.data.roles;

        var rolesFilter = new Array();
        if(roles) {
          Object.entries(roles).forEach(function(item, key){
            //console.log(item);
            var optionItem = {
              label: item[1].label,
              value: item[1].id
            }
            rolesFilter.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.rolesFilter.length == 0) {
          component.setState({
            rolesFilter: rolesFilter,
            roles: roles,
          });
        }




        const tracksById = fill(response.data.result.length).reduce((acc, i) => {

          //const tracks = fill(Math.floor(Math.random() * MAX_NUM_OF_SUBTRACKS) + 1);
          var tracks;

          var elements = new Array();

          Object.entries(response.data.result[i].vacations).forEach(function(item, key){
            //console.log(item);
            var start = new Date(item[1].field_start[0].value);
            var end = new Date(item[1].field_end[0].value);

            const copy = new Date(Number(end))
            copy.setDate(end.getDate() + 1)

            //console.log(end);
            //console.log(copy);
            end = copy;
            var nid = item[1].nid[0].value;
            if(component.state.vacationCategory) {
              //console.log(parseInt(item[1].field_vacation_category[0].target_id));
              //console.log( component.state.vacationCategory);

              var element = {
                id: nid,
                field_employee: item[1].field_employee[0].target_id,
                field_vacation_category: item[1].field_vacation_category[0].target_id,
                field_start: item[1].field_start[0].value,
                field_end: item[1].field_end[0].value,
                field_body: item[1].body[0] ? item[1].body[0].value : "",
                field_quota: item[1].field_quota[0] ? item[1].field_quota[0].value : "",
                title: item[1].body[0] ? item[1].body[0].value : (component.state.vacationCategory[parseInt(item[1].field_vacation_category[0].target_id)][0]),
                start,
                end,
                style: {
                  backgroundColor: '#' + component.state.vacationCategory[parseInt(item[1].field_vacation_category[0].target_id)][1],
                  color: '#ffffff',
                  borderRadius: '4px',
                  boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
                  textTransform: 'capitalize',
                },
              }

              elements.push(element)
              //console.log(element);
            }
          });


          var title = '';
          var quotaForm = '';


          if(response.data.result[i].vacationQuota[0]) {
            title = response.data.result[i].user.field_firstname[0].value + ' ' + response.data.result[i].user.field_lastname[0].value + ' (' + '' + response.data.result[i].vacationQuota[0].field_quota[0].value + ')';
            quotaForm = <VacationQuotaForm key={'vacation-qutoa-form-' + response.data.result[i].user.uid[0].value + response.data.result[i].vacationQuota[0].changed[0].value} nid={response.data.result[i].vacationQuota[0].nid[0].value} quota={response.data.result[i].vacationQuota[0].field_quota[0].value} searchComponent={component} uid={response.data.result[i].user.uid[0].value} year={component.state.filters['field_year']} />;
          } else {
            title = response.data.result[i].user.field_firstname[0].value + ' ' + response.data.result[i].user.field_lastname[0].value;
            quotaForm = <VacationQuotaForm key={'vacation-qutoa-form-' + response.data.result[i].user.uid[0].value} nid={null} searchComponent={component} uid={response.data.result[i].user.uid[0].value} year={component.state.filters['field_year']} />;
          }

          let track = {
            id: `track-${i}`,
            title: title,
            quotaForm: quotaForm,
            elements: elements,
            tracks,
            // hasButton: true,
            // link: 'www.google.com',
            isOpen: false,
          }
          acc[track.id] = track;

          //console.log(acc);

          return acc;
        }, {})


        //console.log(Object.values(tracksById));


        component.setState({ tracksById: tracksById })
        component.setState({ tracks: Object.values(tracksById) })

        //console.log(response.data.total);


        component.setState({
          result: result,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });

      });
  }

  handleToggleOpen = () => {
    this.setState(({ open }) => ({ open: !open }))
  }

  handleZoomIn = () => {
    this.setState(({ zoom }) => ({ zoom: Math.min(zoom + 10, MAX_ZOOM) }))
  }

  handleZoomOut = () => {
    this.setState(({ zoom }) => ({ zoom: Math.max(zoom - 10, MIN_ZOOM) }))
  }

  handleToggleTrackOpen = track => {
    this.setState(state => {
      const tracksById = {
        ...state.tracksById,
        [track.id]: {
          ...track,
          isOpen: !track.isOpen,
        },
      }

      return {
        tracksById,
        tracks: Object.values(tracksById),
      }
    })
  }



  render() {
    let component = this;

    const now = new Date();

    const timebar = buildTimebar(this.state.filters.field_year)

    // eslint-disable-next-line no-alert
    const clickElement = element => alert(`Clicked element\n${JSON.stringify(element, null, 2)}`)



    const { open, zoom, tracks } = this.state
    const start = new Date(`${this.state.filters.field_year}`)
    const end = new Date(`${this.state.filters.field_year + NUM_OF_YEARS}`)


    //console.log(zoom);

    if(zoom < 30) {
      delete timebar[2];
    }
    //console.log(timebar);

    return (
      <>

        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'vacation-loader'} red /></div>
        }

        {component.state.loaded && (
          <>
            {PermissionHelper._hasPermission('restful post Vacation') === true &&
              <>
                <VacationForm searchComponent={this} ref={this.formRef} />
              </>
            }
            <hr />
            <VacationFilter searchComponent={component} />
            <hr />

            { component.state.result.length > 0 && (
              <>
              <Timeline
          scale={{
            start,
            end,
            zoom,
            zoomMin: MIN_ZOOM,
            zoomMax: MAX_ZOOM,
          }}
          isOpen={open}
          toggleOpen={this.handleToggleOpen}
          zoomIn={this.handleZoomIn}
          zoomOut={this.handleZoomOut}
          clickElement={element => {

            //console.log(element);

            //console.log(component.formRef.current.categorySelectRef);

            // eslint-disable-next-line no-alert
            component.formRef.current.setState({
              nid: element.id,
              field_employee: [element.field_employee],
              field_start: element.field_start,
              field_end: element.field_end,
              field_quota: element.field_quota,
              field_body: element.field_body,
              field_vacation_category: [element.field_vacation_category],
            }, component.formRef.current._onToggleModal());



          //  component.formRef.current.employeeSelectRef.current.selectOption(element.field_employee);
          //  component.formRef.current.categorySelectRef.current.selectOption(element.field_vacation_category);






          //  alert(JSON.stringify(element));
            //alert(JSON.stringify(track))
          }}
          clickTrackButton={track => {
            // eslint-disable-next-line no-alert
            //alert(JSON.stringify(track))
          }}
          timebar={timebar}
          tracks={tracks}
          now={now}
          toggleTrackOpen={this.handleToggleTrackOpen}
          enableSticky
          scrollToNow
        />
              </>
            )}

            {component.state.result.length == 0 &&
              <div className="empty">
                <Empty />
              </div>
            }


            <br />

            <div className='vacation-legend'>
              <div className='legend-title'>Legende</div>
              <div className='legend-scale'>
                <ul className='legend-labels'>

                  {Object.values(this.state.vacationCategory).map((value, index) => {
                    return <li key={index}><span style={{backgroundColor: "#" + value[1]}}></span>{value[0]}</li>
                  })}

                </ul>
              </div>
            </div>

          </>
        )}

        {component.state.loaded && component.state.total > 10 && false && (
          <>
            <hr />
            <Pager
                  total={Math.ceil(this.state.total / this.state.limit)}
                  current={this.state.page}
                  visiblePages={this.state.visiblePage}
                  titles={{ first: '<|', last: '>|' }}
                  className="pagination-sm pull-right"
                  onPageChanged={this._onHandlePageChanged}
              />
          </>
        )}

      </>
    );
  }
}

export default VacationSearch;
